import { Outlet } from 'react-router-dom';
import { SchedulerSavedViewContext } from '.';
import { ActionHistoryContext, LocationContext, ProvidersContext, RoomsContext } from '@/hooks';
import { AppointmentTypeContext } from './appointmentTypeContext';

export function SchedulerContexts() {
  return (
    <ActionHistoryContext>
      <RoomsContext>
        <ProvidersContext>
          <AppointmentTypeContext>
            <LocationContext>
              <SchedulerSavedViewContext>
                <Outlet />
              </SchedulerSavedViewContext>
            </LocationContext>
          </AppointmentTypeContext>
        </ProvidersContext>
      </RoomsContext>
    </ActionHistoryContext>
  );
}
