import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Calendar } from 'primereact/calendar';
import { useCallback, useState } from 'react';

import { useOutsideClick } from '@/hooks';
import { DateTimeHelper } from '@/helpers';

type Props = {
  date: Date;
  onChange: (date: Date) => void;
};

export const CalendarPicker = ({ date, onChange }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const formattedDate = DateTimeHelper.returnOnlyDateYYYYmmDD(date);
  const luxonDate = DateTime.fromFormat(formattedDate, 'yyyy-MM-dd').startOf('day');
  const divRef = useOutsideClick(() => setIsVisible(false));

  const toggleCalendar = useCallback(() => {
    setIsVisible((prev) => !prev);
  }, []);

  const handleOnChange = useCallback(
    (value: Date) => {
      const now = new Date();
      value.setHours(now.getHours());
      value.setMinutes(now.getMinutes());
      onChange(value);
    },
    [onChange]
  );

  return (
    <div ref={divRef} className='relative'>
      <div
        onClick={toggleCalendar}
        className={classNames(
          'px-4 flex flex-col items-center transition rounded-lg cursor-pointer hover:bg-kelp/5 border hover:border-kelp',
          {
            'border-kelp/70 ring-4 ring-kelp/30': isVisible,
            'border-transparent': !isVisible,
          }
        )}
      >
        <span className='w-[120px] font-bold text-center text-lg lg:text-xl text-kelp'>
          {luxonDate.toFormat('EEEE')}
        </span>
        <span className='text-kelp text-sm lg:text-base block'>{luxonDate.toFormat('MMM dd')}</span>
      </div>
      {isVisible && (
        <div className='absolute left-1/2 -translate-x-1/2 top-14 z-50'>
          <Calendar inline value={date} onChange={(e) => handleOnChange(e.value as Date)} />
        </div>
      )}
    </div>
  );
};
