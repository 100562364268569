import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import { Button } from 'primereact/button';
import { useCallback, useEffect, useState } from 'react';

export function CheckNetworkConnectivity() {
  const [isVisible, setIsVisible] = useState(false);

  const handleRetryButton = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    const online = () => setIsVisible(false);
    const offline = () => setIsVisible(true);
    window.addEventListener('offline', offline);
    window.addEventListener('online', online);
    return () => {
      window.removeEventListener('offline', offline);
      window.removeEventListener('online', online);
    };
  }, []);
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          variants={{
            open: { translateY: 0, opacity: 1 },
            closed: { translateY: '100%', opacity: 0 },
          }}
          transition={{ duration: 0.8, ease: cubicBezier(0.65, 0, 0.35, 1) }}
          initial='closed'
          animate='open'
          exit='closed'
          className='w-screen h-screen overflow-hidden fixed left-0 top-0 bg-product-sand-200 flex flex-col gap-5 items-center justify-between z-[99999] p-10'
        >
          <div className='flex-1 flex flex-col items-center justify-center gap-4'>
            <h1 className='font-display text-experimental-forest text-center text-4xl'>
              Connect to the internet
            </h1>
            <p className='font-body text-experimental-forest text-center text-base'>
              You{`'`}re offline. Check your connection.
            </p>
            <div>
              <Button className='mr-2' label='Retry' onClick={handleRetryButton} />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
