import { useCallback, useEffect, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';

import {
  AppointmentsFormContext,
  useAppointmentTypeContext,
  useSchedulerSavedViewContext,
  useToastContext,
} from '@/contexts';
import { useFilters, useLocalStorage, useLocation, useProviders, useRooms } from '@/hooks';
import { Room } from '@/@types';
import { Header, Scheduler } from '@/components';

export function SchedulerPage() {
  const {
    findAllLocations,
    locations: locationOptionsList,
    selectedRegion,
    setSelectedRegion,
  } = useLocation();
  const { findAllViewsForUser } = useSchedulerSavedViewContext();
  const { findAllRooms } = useRooms();
  const { findAllProviders } = useProviders();
  const { getAppointmentTypes } = useAppointmentTypeContext();
  const { toast } = useToastContext();
  const [loadTryCount, setLoadTryCount] = useLocalStorage('initalStateLoadTry', 0);
  const [showFilters, setShowFilters] = useState(false);
  const { rooms: roomsOptionsList } = useRooms();
  const {
    providers: providersOptionsList,
    equipmentList,
    providersList,
    cmosFilterList,
  } = useProviders();
  const { appointmentTypesOptionsList } = useAppointmentTypeContext();
  const { selectCurrentSchedulerView } = useSchedulerSavedViewContext();
  const { filters, setFilterItem, setFilters, isViewingDefaultView } = useFilters();

  const roomsFilteredOptions: Room[] = useMemo(() => {
    return roomsOptionsList.filter((room) => room.location?.id === selectedRegion?.id) || [];
  }, [roomsOptionsList, selectedRegion]);

  const initialLoad = useCallback(async () => {
    loader.show();
    try {
      await Promise.all([
        findAllRooms(),
        findAllProviders(),
        findAllLocations(),
        findAllViewsForUser(),
        getAppointmentTypes(),
      ]);
      setLoadTryCount(0);
      loader.hide();
    } catch (error) {
      const seconds = (loadTryCount + 1) * 5;
      toast?.current?.show({
        className: 'text-xl opacity-100',
        severity: 'error',
        summary: 'Failed to load important data',
        detail: `Reloading the page in ${seconds} seconds. If error persists, message Slack channel #scheduler-buildout or contact Teresa Rufin`,
        life: 60000,
      });
      window.setTimeout(() => window.location.reload(), seconds * 1000);
      setLoadTryCount(loadTryCount + 1);
    }
  }, [
    findAllLocations,
    findAllProviders,
    findAllRooms,
    findAllViewsForUser,
    getAppointmentTypes,
    loadTryCount,
    setLoadTryCount,
    toast,
  ]);

  useEffect(() => {
    initialLoad();
  }, [
    findAllLocations,
    findAllProviders,
    findAllRooms,
    getAppointmentTypes,
    findAllViewsForUser,
    setLoadTryCount,
    initialLoad,
  ]);

  useEffect(() => {
    if (
      !appointmentTypesOptionsList.length ||
      !providersOptionsList.length ||
      !roomsFilteredOptions.length
    ) {
      initialLoad();
    }
  }, [
    appointmentTypesOptionsList.length,
    initialLoad,
    providersOptionsList.length,
    roomsFilteredOptions.length,
  ]);

  return (
    <>
      {!selectedRegion && locationOptionsList?.length && (
        <div className='text-center content-center h-screen'>
          <h2 className='text-2xl font-bold mb-4'>Please choose a practice location:</h2>
          <div className='space-x-4'>
            {locationOptionsList.map((locationOption) => (
              <button
                key={locationOption.id}
                className='min-w-[170px] px-10 py-12
                bg-primary-200 text-white rounded hover:bg-kelp'
                onClick={() => setSelectedRegion(locationOption)}
              >
                {locationOption.name}
              </button>
            ))}
          </div>
        </div>
      )}
      {selectedRegion && (
        <>
          <Header
            onMenuToggle={() => setShowFilters((prev) => !prev)}
            locationOptions={locationOptionsList}
            regionSelected={selectedRegion?.id}
            changeRegion={(regionId) => {
              setSelectedRegion(
                locationOptionsList.find((region) => region.id == regionId) || null
              );
              setFilters((prev) => ({
                ...prev,
                location: regionId,
                rooms: [],
                equipment: [],
                personnel: [],
                cmos: [],
                patients: [],
              }));
              selectCurrentSchedulerView(null);
            }}
          />
          <AppointmentsFormContext
            appointmentTypes={appointmentTypesOptionsList}
            providers={providersOptionsList}
            rooms={roomsFilteredOptions}
          >
            <Scheduler
              showFilters={showFilters}
              filters={filters}
              setFilterItem={setFilterItem}
              roomsOptionsList={roomsFilteredOptions}
              equipmentFilterList={equipmentList}
              providersFilterList={providersList}
              providersOptionsList={providersOptionsList}
              appointmentTypesList={appointmentTypesOptionsList}
              cmosFilterList={cmosFilterList}
              setShowFilters={setShowFilters}
              isViewingDefaultView={isViewingDefaultView}
            />
          </AppointmentsFormContext>
        </>
      )}
    </>
  );
}
