import { DateTime } from 'luxon';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useScheduler } from './useScheduler';
import useQueue from 'react-use-queue';
import { ChangeEvent } from '@/components';
import { AppointmentWithDate, Room, SelectOption } from '@/@types';
import { useToastContext } from '@/contexts';
import * as Sentry from '@sentry/react';
import { LocalStorageHelper, LocalStorageKeys, SchedulerHelper } from '@/helpers';
import { env } from '@/utils/constants';

enum ActionHistorySentryMessage {
  UNDO_LAST_ACTION = 'UNDO_LAST_ACTION',
}

export interface ActionHistory {
  createdAt?: string;
  type: ActionHistoryType;
  data: any;
}

type Type = {
  addNewAction: (data: ActionHistory) => void;
};

const Context = createContext<Type>({
  addNewAction: () => {},
});

type Props = {
  children?: React.ReactNode;
};

export enum ActionHistoryType {
  UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT',
  UPDATE_MULTIPLE_APPOINTMENTS = 'UPDATE_MULTIPLE_APPOINTMENTS',
}

export const ActionHistoryContext = ({ children }: Props) => {
  const { updateAppointment, updateMultipleAppointmentsDnd } = useScheduler();
  const { toast } = useToastContext();
  const queue = useQueue();
  const [actionHistory, setActionHistory] = useState<ActionHistory[]>(
    LocalStorageHelper.getItem(LocalStorageKeys.ActionHistory) ?? []
  );
  const [undoCount, setUndoCount] = useState(0);

  const updateMultipleAppointmentsOnDrop = useCallback(
    async (
      eventRaw: ChangeEvent & {
        roomsOptionsList: Room[];
        appointmentsSelected: AppointmentWithDate[];
      }
    ) => {
      const { roomsOptionsList, appointmentsSelected } = eventRaw;
      const appointments = SchedulerHelper.getUniqueAppointmentsList(appointmentsSelected);
      const atriaAppts = appointments.filter((appt) => appt.atriaAppointment);
      const athenaAppts = appointments.filter((appt) => !appt.atriaAppointment);
      const roomsOptions = roomsOptionsList.map((er) => ({ id: er.id, label: er.name }));

      const atria = atriaAppts.map((appt) => {
        const eventWithCorrectDate = appointmentsSelected?.find((event) => event.id == appt.id);
        const selectedRooms = appt.roomsIds.map((roomIdsItem: number) => ({
          id: roomIdsItem,
        })) as SelectOption[];

        return {
          id: appt.id,
          roomsIds: SchedulerHelper.getRelatedNewRooms(selectedRooms, roomsOptions).map(
            (r) => r.id
          ),
          start: eventWithCorrectDate?.date.toISOString(),
          date: eventWithCorrectDate?.date.toISOString(),
          end: eventWithCorrectDate?.end.toISOString(),
        };
      });

      const athena = athenaAppts.map((appt) => {
        const selectedRooms = appt.roomsIds.map((roomIdsItem: number) => ({
          id: roomIdsItem,
        })) as SelectOption[];

        return {
          id: appt.id,
          roomsIds: SchedulerHelper.getRelatedNewRooms(selectedRooms, roomsOptions).map(
            (r) => r.id
          ),
        };
      });

      await updateMultipleAppointmentsDnd({
        atria,
        athena,
      });
    },
    [updateMultipleAppointmentsDnd]
  );

  const processQueue = useCallback(
    (lastAction: ActionHistory) => async () => {
      try {
        const data = lastAction.data;

        switch (lastAction.type) {
          case ActionHistoryType.UPDATE_APPOINTMENT:
            await updateAppointment(data.event.appointmentId, data.event, true);
            break;
          case ActionHistoryType.UPDATE_MULTIPLE_APPOINTMENTS:
            await updateMultipleAppointmentsOnDrop(data);
            break;
          default:
            break;
        }

        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'The undo was executed successfully',
          life: 3000,
        });
      } catch {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'An unexpected error occurred in the undo and the action could not be completed',
          life: 3000,
        });
      }
    },
    [toast, updateAppointment, updateMultipleAppointmentsOnDrop]
  );

  const keyPress = (e: KeyboardEvent) => {
    if (!(e.code == 'KeyZ' && (e.ctrlKey || e.metaKey))) return;

    setUndoCount((prev) => prev + 1);
  };

  const queueUndoLastAction = useCallback(() => {
    const availableActions = actionHistory.filter((action: ActionHistory) =>
      DateTime.fromISO(action.createdAt!).hasSame(DateTime.now(), 'day')
    );

    const userString = localStorage.getItem(LocalStorageKeys.OktaTokenStorage);
    const userParsed = userString ? JSON.parse(userString) : {};

    Sentry.captureEvent({
      message: ActionHistorySentryMessage.UNDO_LAST_ACTION,
      level: 'log',
      user: {
        id: userParsed?.idToken?.claims?.email,
      },
      extra: {
        actionHistory: actionHistory.map((action) => ({ ...action, data: undefined })),
        actionHistoryData: actionHistory.map((action) => action.data.appointmentsSelected),
        availableActions: availableActions.map((action) => ({ ...action, data: undefined })),
        availableActionsData: availableActions.map((action) => action.data.appointmentsSelected),
      },
    });

    if (availableActions.length <= 0) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'No changes available to undo',
        life: 3000,
      });

      return setUndoCount((prev) => prev - 1);
    }

    const lastAction = availableActions.pop() as ActionHistory;

    LocalStorageHelper.setItem({
      key: LocalStorageKeys.ActionHistory,
      value: [...availableActions],
    });

    setActionHistory([...availableActions]);
    setUndoCount((prev) => prev - 1);
    queue.addJob({
      task: processQueue(lastAction),
    });
  }, [actionHistory, queue, processQueue, toast]);

  useEffect(() => {
    if (undoCount > 0) queueUndoLastAction();
  }, [undoCount, queueUndoLastAction]);

  // TODO - Find valid type for a custom event receiving an ActionHistory
  const storageChange = (event: any) => {
    setActionHistory(event.detail);
  };

  const addNewAction = useCallback(
    (data: ActionHistory) => {
      const dateTime = new Date().toISOString();
      const actions = actionHistory.length >= 5 ? actionHistory.slice(1) : actionHistory;

      const lastAction = {
        ...data,
        createdAt: dateTime,
      };
      const valueAction = [...actions, lastAction];

      LocalStorageHelper.setItem({
        key: LocalStorageKeys.ActionHistory,
        value: valueAction,
      });

      setActionHistory(valueAction);
    },
    [actionHistory]
  );

  useEffect(() => {
    if (!env.APP_FEATURE_FLAGS.IS_TO_ENABLE_UNDO_HISTORY) return;

    window.addEventListener('NewActionAdded', storageChange);
    window.addEventListener('keydown', keyPress);

    return () => {
      window.removeEventListener('NewActionAdded', storageChange);
      window.removeEventListener('keydown', keyPress);
    };
  }, []);

  return (
    <Context.Provider
      value={{
        addNewAction,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useActionHistory = () => useContext(Context);
