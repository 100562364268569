import { useGoogleLogin } from '@react-oauth/google';
import { useCallback, useEffect, useState } from 'react';

import { LocalStorageHelper, LocalStorageKeys } from '@/helpers';
import { googleService } from '@/services';
import { env } from '@/utils/constants';

export function useGoogleAuth() {
  const [isLoggedInToGoogle, setIsLoggedInToGoogle] = useState(
    !!LocalStorageHelper.getItem(LocalStorageKeys.GoogleRefreshToken)
  );

  useEffect(() => {
    const handleStorage = () => {
      const isLoggedIn = !!LocalStorageHelper.getItem(LocalStorageKeys.GoogleRefreshToken);
      setIsLoggedInToGoogle(isLoggedIn);
    };

    window.addEventListener(LocalStorageKeys.GoogleRefreshToken, handleStorage);
    return () => window.removeEventListener(LocalStorageKeys.GoogleRefreshToken, handleStorage);
  }, []);

  const onLoginSuccess = useCallback(() => {
    setIsLoggedInToGoogle(true);
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setIsLoggedInToGoogle(false);
  }, []);

  const googleLogin = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/calendar',
    flow: 'auth-code',
    redirect_uri: env.APP_FRONT_URL,
    onSuccess: async ({ code }) => {
      const tokens = await getGoogleToken(code);
      const expiresInOneWeek = new Date();
      expiresInOneWeek.setDate(expiresInOneWeek.getDate() + 7);

      if (tokens && tokens.refreshToken) {
        LocalStorageHelper.setItem({
          key: LocalStorageKeys.GoogleRefreshToken,
          value: tokens.refreshToken,
          expiresIn: expiresInOneWeek.getTime(),
        });
        onLoginSuccess();
      }
    },
  });

  const googleLogout = useCallback(() => {
    LocalStorageHelper.removeItem(LocalStorageKeys.GoogleRefreshToken);
    onLogoutSuccess();
  }, [onLogoutSuccess]);

  const getGoogleToken = useCallback(async (code: string) => {
    const { data: token } = await googleService.getToken({ code });
    return token;
  }, []);

  const getGoogleRefreshToken = useCallback(async (refreshToken: string) => {
    const { data: token } = await googleService.getRefreshToken({ refreshToken });
    return token;
  }, []);

  return {
    isLoggedInToGoogle,
    googleLogin,
    googleLogout,
    getGoogleToken,
    getGoogleRefreshToken,
  };
}
