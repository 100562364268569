import { AppointmentWithDate, Atria, Origin } from '@/@types';
import { ATHENA_PRACTICE_ID } from '@/data/data';
import { env } from './constants';

const homeAthenaDepartment = [
  Atria.AthenaDepartment.NY_HOME_SERVICES,
  Atria.AthenaDepartment.PB_HOME_SERVICES,
];
const otherAthenaDepartment = [
  Atria.AthenaDepartment.NY_INSTITUTE,
  Atria.AthenaDepartment.PB_INSTITUTE,
  Atria.AthenaDepartment.NY_PEDIATRICS,
  Atria.AthenaDepartment.PB_PEDIATRICS,
];
const nyAthenaDepartment = [
  Atria.AthenaDepartment.NY_INSTITUTE,
  Atria.AthenaDepartment.NY_HOME_SERVICES,
  Atria.AthenaDepartment.NY_PEDIATRICS,
];
const enum HomeVisitRooms {
  NY = 16,
  PB = 31,
}

export const parseAppointments = (
  appointments: Atria.Appointment[],
  suiteRooms: number[]
): AppointmentWithDate[] => {
  const results: AppointmentWithDate[] = [];
  for (const appointment of appointments) {
    const isValidProviderAppointment = checkIfValidProviderAppointment(appointment);
    const item = mountAppointmentObject(appointment, isValidProviderAppointment);
    results.push(item);

    const athenaDepartmentId = appointment.athenaDepartmentId as Atria.AthenaDepartment;
    const isHomeAthenaDepartment = homeAthenaDepartment.includes(athenaDepartmentId);
    const isHomeVisitTypeInOtherDepartament =
      otherAthenaDepartment.includes(athenaDepartmentId) &&
      appointment.type?.toLowerCase().includes('home');
    if (appointment.rooms?.length || isHomeAthenaDepartment || isHomeVisitTypeInOtherDepartament) {
      const homeVisitRoomId = nyAthenaDepartment.includes(athenaDepartmentId)
        ? HomeVisitRooms.NY
        : HomeVisitRooms.PB;
      const isHomeVisitNotMappedYet = !appointment.rooms.find(
        (room) => room.id === homeVisitRoomId
      );
      const isToAddAppointmentToHomeVisitRoom =
        isHomeVisitNotMappedYet && (isHomeAthenaDepartment || isHomeVisitTypeInOtherDepartament);
      const rooms = isToAddAppointmentToHomeVisitRoom
        ? [...appointment.rooms, { id: homeVisitRoomId }]
        : appointment.rooms;
      for (const { id } of rooms) {
        results.push({
          ...item,
          resourceId: `room-${id}`,
          hasPatientVisit: !!(
            suiteRooms.includes(id) &&
            appointments.find(
              (appt) =>
                appt.patientId &&
                appointment.patientId &&
                appt.patientId === appointment.patientId &&
                appt.rooms.length > 0
            )
          ),
        });
      }
    }

    if (appointment.providers.length) {
      for (const { id, type } of appointment.providers) {
        if (type === 'EQUIPMENT') {
          results.push({
            ...item,
            resourceId: `equipment-${id}`,
          });
        }
        if (isValidProviderAppointment) {
          results.push({
            ...item,
            resourceId: `provider-${id}`,
          });
        }
      }
    }

    if (appointment.patientPrimaryProviderId && isValidProviderAppointment) {
      results.push({
        ...item,
        resourceId: `cmo-${appointment.patientPrimaryProviderId}`,
      });
    }

    if (appointment.patientId) {
      results.push({ ...item, resourceId: `patient-${appointment.patientId}` });
    }
  }
  return results;
};

export const mountAppointmentObject = (
  appointment: Atria.Appointment,
  isValidProviderAppointment = true
): AppointmentWithDate => {
  return {
    ...appointment,
    ...(isValidProviderAppointment && {
      resourceId: `provider-${appointment.providerId!}`,
    }),
    appointmentId: appointment.id,
    date: new Date(appointment.date),
    end: new Date(appointment.end),
    updatedAt: new Date(appointment.updatedAt),
    provider: {
      id: appointment.providerId!,
      label: appointment.providerName!,
    },
    providers: appointment.providers.map(({ id, name, type }) => ({ id, name, type })),
    rooms: appointment.rooms.map(({ id, name: label }) => ({ id, label })),
    origin: appointment.atriaAppointment ? Origin.Atria : Origin.Athena,
  };
};

export function checkIfValidProviderAppointment(appointment: {
  type?: string | null;
  atriaAppointment: boolean;
}) {
  const athenaTypeAllowList = ['home', 'tele', 'phone call'];
  return (
    appointment.atriaAppointment ||
    athenaTypeAllowList.some((t) => appointment.type?.toLowerCase().includes(t))
  );
}

/**
 * Return athena deeplink to patient chart
 * @param patientId
 * @returns
 */
export function getPatientAthenaChartUrl(patientId?: number | null) {
  if (!patientId || !env.APP_ATHENA_URL) {
    return '';
  }
  return `${env.APP_ATHENA_URL}/1/1/login/startoidc.esp?DEEPLINK=1&TARGETURL=/${ATHENA_PRACTICE_ID}/1/client/clientsummary.esp?ID=${patientId}`;
}
/**
 * Check if appointment of type telehealth
 */
export function isTelehealth(appointmentType: Atria.Appointment['type'] | undefined) {
  if (appointmentType) {
    return appointmentType.toLowerCase().includes('tele');
  }
  return false;
}

type ValidAthenaAppointment = Pick<
  Atria.Appointment,
  'confirmed' | 'patientId' | 'providerId' | 'type' | 'date'
>;

/**
 * Check if appointment meets athena sync requirements
 */
export function checkIfEnableSchedulerAthenaSync(data: ValidAthenaAppointment) {
  return !!(
    isTelehealth(data.type) &&
    data.confirmed &&
    data.providerId &&
    data.patientId &&
    data.date &&
    env.APP_FEATURE_FLAGS.IS_TO_SHOW_ATHENA_APPOINTMENT_SYNC
  );
}
