import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AppointmentType, AppointmentWithDate, Atria, Provider, SelectOption } from '@/@types';
import { useSchedulerDuplicateAppointment } from '@/hooks';

import styles from './AppointmentForm.module.css';
import {
  DuplicateMultipleAppointmentsFormSchemaResolver,
  DuplicateMultipleAppointmentsFormType,
} from './DuplicateMultipleAppointmentFormSchema';
import { DateTimeHelper } from '@/helpers';
import { Permission, useAuthContext } from '@/contexts';

type Props = {
  appointments: AppointmentWithDate[];
  rooms: SelectOption[];
  providers: Provider[];
  appointmentTypes: AppointmentType[];
  onCancel: VoidFunction;
  onSave: (newAppointment: Atria.Appointment[]) => void;
};

export const DuplicateMultipleAppointmentForm = ({
  appointments,
  rooms,
  appointmentTypes,
  onCancel,
  onSave,
}: Props) => {
  const { control, formState, handleSubmit, reset } =
    useForm<DuplicateMultipleAppointmentsFormType>({
      resolver: DuplicateMultipleAppointmentsFormSchemaResolver,
    });
  const { getDuplicateMultipleAppointments, createMultipleAppointments } =
    useSchedulerDuplicateAppointment();
  const hasErrors = useMemo(() => Object.values(formState.errors).length > 0, [formState]);

  const handleCancelFormButton = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  const { hasPermission } = useAuthContext();

  const roomsOptions = useMemo(() => {
    if (hasPermission(Permission['VIEW_ROOMS:TELE'])) {
      return rooms.filter((room) => room.label.toLowerCase().includes('tele'));
    }

    return rooms;
  }, [rooms, hasPermission]);

  const onSubmit = useCallback(
    async (values: DuplicateMultipleAppointmentsFormType) => {
      const date = DateTime.fromFormat(values.date!, 'M/d/yy').toUTC().toJSDate();
      const list = getDuplicateMultipleAppointments(
        appointments,
        date,
        appointmentTypes,
        rooms,
        values.rooms!
      );

      const results = await createMultipleAppointments(list);
      if (results) {
        onSave(results);
      }
    },
    [
      appointmentTypes,
      appointments,
      createMultipleAppointments,
      getDuplicateMultipleAppointments,
      onSave,
      rooms,
    ]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.row}>
        <Controller
          control={control}
          name='date'
          render={({ field, fieldState }) => (
            <div
              className={classNames(styles.column, {
                [styles.columnError]: !!fieldState.error,
              })}
            >
              <label htmlFor='date' className={styles.label}>
                Date
              </label>
              <Calendar
                {...field}
                id='date'
                placeholder='Date'
                dateFormat='mm/dd/yy'
                locale='en'
                minDate={DateTime.fromFormat(
                  DateTimeHelper.returnOnlyDateYYYYmmDD(new Date()),
                  'yyyy-MM-dd'
                ).toJSDate()}
              />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          control={control}
          name='rooms'
          render={({ field, fieldState }) => (
            <div
              className={classNames([
                styles.column,
                styles.multiSelectColumn,
                { [styles.columnError]: !!fieldState.error },
              ])}
            >
              <label htmlFor='rooms' className={styles.label}>
                Rooms
              </label>
              <MultiSelect
                id='rooms'
                placeholder='Select rooms'
                filter
                optionLabel='label'
                options={roomsOptions}
                onChange={(e) => field.onChange(e.value)}
                onBlur={field.onBlur}
                value={field.value}
              />
              {fieldState.error?.message && (
                <span className={styles.errorLabel}>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />
      </div>
      <div className='w-full flex flex-col gap-2 mt-4'>
        <Button label='Save' size='small' className='w-full' type='submit' disabled={hasErrors} />
        <Button
          label='Cancel'
          size='small'
          className='w-full'
          type='reset'
          onClick={handleCancelFormButton}
          outlined
        />
      </div>
    </form>
  );
};
