import { useState, memo, useCallback, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import { DateTime } from 'luxon';

import { DateTimeHelper } from '@/helpers';
import styles from './DailyNotesDrawer.module.css';

type DailyNotesDrawerProps = {
  visible: boolean;
  onHide: () => void;
  currentDate: Date;
  dailyNotes: string;
  onDailyNotesSave: (newDailyNotes: string) => void;
};

export const DailyNotesDrawer = ({
  visible,
  onHide,
  currentDate,
  dailyNotes,
  onDailyNotesSave,
}: DailyNotesDrawerProps) => {
  const luxonDate = DateTime.fromFormat(
    DateTimeHelper.returnOnlyDateYYYYmmDD(currentDate),
    'yyyy-MM-dd'
  ).toFormat('EEEE MMM dd');
  const [editorDailyNotes, setEditorDailyNotes] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const handleTextOnChange = useCallback((e: EditorTextChangeEvent) => {
    setEditorDailyNotes(e.htmlValue!);
  }, []);

  const onCancel = useCallback(async () => {
    setEditorDailyNotes(dailyNotes);
    setIsEditing(false);
  }, [dailyNotes]);

  const onSave = useCallback(async () => {
    onDailyNotesSave(editorDailyNotes);
    setIsEditing(false);
  }, [editorDailyNotes, onDailyNotesSave]);

  const onClose = useCallback(async () => {
    setIsEditing(false);
    onHide();
  }, [onHide]);

  useEffect(() => {
    setEditorDailyNotes(dailyNotes);
  }, [dailyNotes]);

  return (
    <Sidebar
      visible={visible}
      position='right'
      onHide={onHide}
      className={styles.container}
      showCloseIcon={false}
    >
      <header className={styles.header}>
        <h1>Daily notes - {luxonDate}</h1>
        <Button icon='pi pi-times' rounded text aria-label='close' onClick={onClose} />
      </header>
      <main className={styles.content}>
        {isEditing ? (
          <Editor
            value={editorDailyNotes}
            onTextChange={handleTextOnChange}
            headerTemplate={<RenderHeader />}
            style={{ height: '150px' }}
          />
        ) : dailyNotes ? (
          <p className={styles.notes} dangerouslySetInnerHTML={{ __html: dailyNotes }} />
        ) : (
          <p className={styles.noNotes}>No notes registered</p>
        )}
      </main>
      <footer className={styles.footer}>
        {isEditing ? (
          <>
            <Button label='Save' size='small' onClick={onSave} className={styles.saveButton} />
            <Button label='Cancel' outlined size='small' onClick={onCancel} />
          </>
        ) : (
          <Button
            label='Edit'
            icon='pi pi-pencil'
            iconPos='right'
            outlined
            size='small'
            onClick={() => setIsEditing(true)}
          />
        )}
      </footer>
    </Sidebar>
  );
};

const RenderHeader = memo(
  function RenderHeaderMemoized() {
    return (
      <span className='ql-formats'>
        <button className='ql-bold' aria-label='Bold'></button>
        <button className='ql-italic' aria-label='Italic'></button>
        <button className='ql-underline' aria-label='Underline'></button>
      </span>
    );
  },
  () => true
);
