import { Origin } from '.';

export type GoogleEventsWithDate = {
  date: Date;
  end: Date;
  type: string;
  typeId: number;
  resourceId: string;
  origin: Origin;
  providerId: number;
  isOutOfOffice: boolean;
  isAllDay: boolean;
};

export type GoogleEventProvider = {
  id: string;
  name: string;
  homeDepartment: string;
  locationId: number | null;
};

export const googleWorkingTypeToLocationMap: Record<string, string> = {
  homeOffice: 'Home',
  officeLocation: 'Office',
};
