import { api } from '@/api';

type Filters = {
  equipment?: number[];
  personnel?: number[];
  rooms?: number[];
  cmos?: number[];
};

type SchedulerViewForUser = {
  id: number;
  name: string;
  locationId: number;
  filters?: Filters;
};

type DeleteUserSchedulerViewResponse = {
  success: boolean;
};

async function findAllSchedulerViewForUser() {
  return api.get<SchedulerViewForUser[]>(`/api/v1/users/me/scheduler-views`);
}

async function createSchedulerViewForUser(savedView: Omit<SchedulerViewForUser, 'id'>) {
  return api.post<SchedulerViewForUser>(`/api/v1/users/me/scheduler-views`, savedView);
}

async function deleteUserSchedulerView(id: number) {
  return api.delete<DeleteUserSchedulerViewResponse>(`/api/v1/users/me/scheduler-views/${id}`);
}

async function updateSchedulerView(
  id: number,
  data: Omit<SchedulerViewForUser, 'id' | 'filters' | 'locationId'>
) {
  return api.patch<DeleteUserSchedulerViewResponse>(`/api/v1/users/me/scheduler-views/${id}`, data);
}

export const usersServices = {
  findAllSchedulerViewForUser,
  createSchedulerViewForUser,
  deleteUserSchedulerView,
  updateSchedulerView,
};
