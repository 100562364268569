import { zodResolver } from '@hookform/resolvers/zod';
import { DateTime } from 'luxon';
import * as z from 'zod';
import { AppointmentFormBaseSchema, AppointmentTimeSchema } from './AppointmentCommonFormSchema';

const EditAppointmentWithTimeFormSchema = AppointmentFormBaseSchema.and(AppointmentTimeSchema)
  .refine(
    ({ start }) => {
      const today = DateTime.now().toFormat('yyyy-MM-dd');
      const startTimeSelected = DateTime.fromFormat(`${today} ${start}`, 'yyyy-MM-dd HH:mm');
      const startTimeReference = DateTime.fromFormat(`${today} 07:00`, 'yyyy-MM-dd HH:mm');
      return startTimeSelected >= startTimeReference;
    },
    { message: 'The start time cannot be before 07:00 am', path: ['start'] }
  )
  .refine(
    ({ date, start }) => {
      const dateStart = DateTime.fromFormat(`${date} ${start}`, 'M/d/yy T');
      if (!dateStart.isValid) return false;
      const startTimeMax = DateTime.fromObject({
        day: dateStart.day,
        month: dateStart.month,
        year: dateStart.year,
        hour: 18,
        minute: 45,
      });

      return dateStart <= startTimeMax;
    },
    {
      message: 'The start time cannot be after 06:45 pm',
      path: ['start'],
    }
  )
  .refine(
    ({ date, start, end }) => {
      const dateStart = DateTime.fromFormat(`${date} ${start}`, 'M/d/yy T');
      const dateEnd = DateTime.fromFormat(`${date} ${end}`, 'M/d/yy T');
      if (!dateEnd.isValid || !dateStart.isValid) return false;

      const endTimeMin = dateStart.plus({ minute: 15 });

      return dateEnd >= endTimeMin;
    },
    {
      message: 'The end time must be at least 15 minutes ahead of the start time',
      path: ['end'],
    }
  )
  .refine(
    ({ date, end }) => {
      const dateEnd = DateTime.fromFormat(`${date} ${end}`, 'M/d/yy T');
      if (!dateEnd.isValid) return false;
      const startTimeMax = DateTime.fromObject({
        day: dateEnd.day,
        month: dateEnd.month,
        year: dateEnd.year,
        hour: 19,
        minute: 0,
      });

      return dateEnd <= startTimeMax;
    },
    {
      message: 'The end time cannot be after 07:00 pm',
      path: ['end'],
    }
  )
  .refine(
    ({ atriaAppointment, roomsSelected }) => {
      return !atriaAppointment || (atriaAppointment && roomsSelected.length > 0);
    },
    {
      message: 'Please select a room',
      path: ['roomsSelected'],
    }
  );

export type EditAppointmentWithTimeFormType = z.infer<typeof EditAppointmentWithTimeFormSchema>;
export const EditAppointmentWithTimeFormResolver = zodResolver(EditAppointmentWithTimeFormSchema);
