import { AppointmentsSearch, Atria } from '@/@types';
import { appointmentsService } from '@/services';
import { useCallback } from 'react';

export const useAppointments = () => {
  const getAppointments = useCallback(
    async (search: AppointmentsSearch): Promise<Atria.Appointment[]> => {
      const payload: Atria.FindAllAppointments.Params = {
        ...search,
        rangeQueryField: 'date',
        deleteStatus: 'not-deleted',
      };

      const { data: appointments } = await appointmentsService.findAppointmentsByRange(payload);
      return appointments;
    },
    []
  );

  return {
    getAppointments,
  };
};
