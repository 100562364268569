import { ConfirmDialog } from 'primereact/confirmdialog';
import './ConfirmDeleteDialog.css';

type ConfirmDeleteDialogProps = {
  headerText?: string;
  messageText?: string;
  visible: boolean;
  onCancel: () => void;
  onHide: () => void;
  onConfirm: () => void;
};

export const ConfirmDeleteDialog = ({
  headerText = 'Delete appointment',
  messageText = 'Are you sure you want to delete this appointment?',
  visible,
  onHide,
  onCancel,
  onConfirm,
}: ConfirmDeleteDialogProps) => {
  if (!visible) {
    return <></>;
  }
  return (
    <ConfirmDialog
      draggable={false}
      visible={visible}
      onHide={onHide}
      message={messageText}
      header={headerText}
      icon='pi pi-exclamation-triangle'
      accept={onConfirm}
      reject={onCancel}
      className='new-confim-delete-dialog'
    />
  );
};
