import { Sidebar } from 'primereact/sidebar';

import { Atria } from '@/@types';
import { DateTime } from 'luxon';
import { Checkbox } from 'primereact/checkbox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useScheduler } from '@/hooks';
import { Skeleton } from 'primereact/skeleton';
import { CloseButtonImage } from '@/components/CloseButtonImage';
import { Permission, useAuthContext, useToastContext } from '@/contexts';

type AppointmentsDeletedHistoryDrawerProps = {
  visible: boolean;
  onHide: () => void;
  locationId: number;
};

export const AppointmentsDeletedHistoryDrawer = ({
  visible,
  onHide,
  locationId,
}: AppointmentsDeletedHistoryDrawerProps) => {
  const { getDeletedAppointmentsHistory, restoreDeletedAppointments } = useScheduler();

  const [deletedAppointments, setDeletedAppointments] = useState<Atria.Appointment[]>([]);
  const [skeletonAnimation, setSkeletonAnimation] = useState<'wave' | 'none' | undefined>('wave');
  const [skeletonHidden, setSkeletonHidden] = useState(false);

  const { toast } = useToastContext();

  const { hasPermission } = useAuthContext();

  const getDeletedAppointments = useCallback(
    async (startDate: DateTime, endDate: DateTime) => {
      setSkeletonAnimation('wave');
      setSkeletonHidden(false);
      const appointments = await getDeletedAppointmentsHistory(
        startDate.toFormat('MM/dd/yyyy'),
        endDate.toFormat('MM/dd/yyyy'),
        locationId
      );
      setDeletedAppointments(appointments);
      setSkeletonAnimation('none');
      setSkeletonHidden(true);
    },
    [getDeletedAppointmentsHistory, locationId]
  );

  const onRestoreAppointmentsClick = () => {
    const appointmentIds = selectedAppointments.map((appointment) => appointment.appointmentId);
    if (appointmentIds.length === 0) return;
    restoreDeletedAppointments(appointmentIds)
      .then(() => {
        setDeletedAppointments(
          deletedAppointments.filter((appointment) => !appointmentIds.includes(appointment.id))
        );
        setSelectedAppointments([]);
      })
      .catch(() => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to restore appointment(s)',
          life: 3000,
        });
      });
  };

  useEffect(() => {
    if (visible) {
      getDeletedAppointments(DateTime.now().minus({ day: 30 }), DateTime.now()).catch(() => {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load history of deleted appointments',
          life: 3000,
        });
        onHide();
      });
    }
  }, [onHide, toast, getDeletedAppointments, visible]);

  const [selectedAppointments, setSelectedAppointments] = useState<Atria.Appointment[]>([]);

  const onAppointmentSelected = (e: any): void => {
    if (!e.checked) {
      setSelectedAppointments(
        selectedAppointments.filter((appointment) => appointment.id !== e.value)
      );
      return;
    }
    const appointmentSelected = deletedAppointments.find(
      (appointment) => appointment.id == e.value
    );
    setSelectedAppointments(
      appointmentSelected ? [...selectedAppointments, appointmentSelected] : selectedAppointments
    );
  };

  const appointmentsToShow = useMemo(
    () =>
      deletedAppointments.map((appointment) => ({
        id: appointment.id,
        title: appointment.title,
        startDate: DateTime.fromJSDate(new Date(appointment.date)).toFormat('MM/dd/yyyy'),
        startTime: DateTime.fromJSDate(new Date(appointment.date)).toFormat('t'),
        endTime: DateTime.fromJSDate(new Date(appointment.end)).toFormat('t'),
        deletedOnDate: DateTime.fromJSDate(new Date(appointment.deletedAt!)).toFormat('MM/dd/yyyy'),
        deletedBy: appointment.updatedBy,
        patientName: appointment.patientName,
        rooms: appointment.rooms.map((room) => room.name).join(', '),
      })),
    [deletedAppointments]
  );

  return (
    <Sidebar
      visible={visible}
      onHide={onHide}
      position='right'
      showCloseIcon={false}
      className='w-full md:w-[500px]'
      blockScroll={true}
    >
      <div className='flex flex-col h-[100%]'>
        <div className='flex flex-col'>
          <div className='flex flex-row justify-between'>
            <h2 className='text-product-forest-100 text-[36px] font-medium font-scto-grotesk pt-[22px] pr-[22px]'>
              Trash Bin
            </h2>
            <button onClick={onHide} title='close history'>
              <CloseButtonImage style={{ width: 28, height: 24 }} />
            </button>
          </div>
          <h3 className='text-product-grey-400 text-[16px] font-medium font-scto-grotesk leading-[24px] mb-[20px]'>
            Showing deleted appointments from the past 30 days
          </h3>
        </div>

        {skeletonHidden ? (
          <div className='flex flex-col space-y-4 overflow-y-auto flex-1'>
            {appointmentsToShow.length === 0 ? (
              <div className='flex flex-row flex-1 items-center text-product-grey-400 text-[16px] font-medium font-scto-grotesk leading-[24px] mb-[20px]'>
                There are no appointments deleted in the last 30 days.
              </div>
            ) : (
              appointmentsToShow.map((appointment: any) => {
                return (
                  <div key={appointment.id} className='flex flex-row bg-[#f4f1ee] rounded-xl p-7'>
                    <Checkbox
                      value={appointment.id}
                      onChange={onAppointmentSelected}
                      checked={selectedAppointments.some((item) => item.id === appointment.id)}
                      className='pt-[3px]'
                    ></Checkbox>
                    <div className='flex flex-col pl-2 flex-1'>
                      <p className='text-product-forest-100 font-bold text-[18px] font-scto-grotesk'>
                        {appointment.title}
                      </p>
                      <div className='flex flex-row justify-between'>
                        <p className='text-product-grey-400 font-medium text-[16px] font-scto-grotesk flex-1'>
                          {appointment.startDate} <br />
                          {appointment.startTime} - {appointment.endTime} <br />
                          {appointment.patientName || '-'} <br />
                          {appointment.rooms}
                        </p>
                        <p className='text-product-grey-400 font-medium text-[16px] font-scto-grotesk flex-1'>
                          Deleted on: {appointment.deletedOnDate} <br />
                          Deleted by: <br /> {appointment.deletedBy}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        ) : (
          <div className='flex flex-col space-y-4 overflow-y-auto flex-1'>
            <Skeleton className='flex w-[445px] h-[350px]' animation={skeletonAnimation}></Skeleton>
            <Skeleton className='flex w-[445px] h-[350px]' animation={skeletonAnimation}></Skeleton>
            <Skeleton className='flex w-[445px] h-[350px]' animation={skeletonAnimation}></Skeleton>
          </div>
        )}

        <div className='flex flex-row justify-center pt-4'>
          <button
            onClick={onRestoreAppointmentsClick}
            className='w-[434px] h-[50px] bg-[#607663] rounded-xl disabled:opacity-50'
            disabled={!hasPermission(Permission.DELETE_APPOINTMENT)}
          >
            <div className='text-white font-bold'>Restore Selected Appointments</div>
          </button>
        </div>
      </div>
    </Sidebar>
  );
};
