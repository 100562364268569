import { SchedulerViewSettingsOptions, useSchedulerViewSettings } from '@/hooks';
import { createContext, useContext } from 'react';

type Type = SchedulerViewSettingsOptions & {
  onShowFullCalendar: (checked: boolean) => void;
  onHidePatientName: (checked: boolean) => void;
  onShowGoogleCalendarEvents: (checked: boolean) => void;
  onShowAthenaAppointments: (checked: boolean) => void;
  onShowAtriaAppointments: (checked: boolean) => void;
  onShowPatientBackgroundEvents: (checked: boolean) => void;
  onShowUnconfirmedAppointments: (checked: boolean) => void;
};

const Context = createContext<Type>({
  showGoogleCalendarEvents: true,
  showFullCalendar: false,
  hidePatientName: false,
  showAthenaAppointments: true,
  showAtriaAppointments: true,
  showPatientBackgroundEvents: true,
  showUnconfirmedAppointments: true,
  showEmptyRooms: true,
  onShowFullCalendar: () => {},
  onHidePatientName: () => {},
  onShowGoogleCalendarEvents: () => {},
  onShowAthenaAppointments: () => {},
  onShowAtriaAppointments: () => {},
  onShowPatientBackgroundEvents: () => {},
  onShowUnconfirmedAppointments: () => {},
});

export function SchedulerViewSettingsContext({ children }: { children?: React.ReactNode }) {
  const {
    showGoogleCalendarEvents,
    showFullCalendar,
    hidePatientName,
    showAthenaAppointments,
    showAtriaAppointments,
    showPatientBackgroundEvents,
    showUnconfirmedAppointments,
    showEmptyRooms,
    onShowFullCalendar,
    onHidePatientName,
    onShowGoogleCalendarEvents,
    onShowAthenaAppointments,
    onShowAtriaAppointments,
    onShowPatientBackgroundEvents,
    onShowUnconfirmedAppointments,
  } = useSchedulerViewSettings();

  return (
    <Context.Provider
      value={{
        showGoogleCalendarEvents,
        showFullCalendar,
        hidePatientName,
        showAthenaAppointments,
        showAtriaAppointments,
        showPatientBackgroundEvents,
        showUnconfirmedAppointments,
        showEmptyRooms,
        onShowFullCalendar,
        onHidePatientName,
        onShowGoogleCalendarEvents,
        onShowAthenaAppointments,
        onShowAtriaAppointments,
        onShowPatientBackgroundEvents,
        onShowUnconfirmedAppointments,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useSchedulerViewSettingsContext = () => useContext(Context);
