import { Resources } from '@/@types';

function order(resources: Resources[]): Resources[] {
  return [
    ...resources.filter((r) => r.resourceId.includes('cmo')),
    ...resources.filter((r) => r.resourceId.startsWith('provider')),
    ...resources.filter((r) => r.resourceId.startsWith('room')),
    ...resources.filter((r) => r.resourceId.startsWith('equipment')),
    ...resources.filter((r) => r.resourceId.startsWith('patient')),
  ];
}

export const OrderColumnsHelper = {
  order,
};
