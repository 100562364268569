import { useCallback, useState } from 'react';
import { env } from '@/utils/constants';
import { CustomSelect, CustomSelectDnd } from '../CustomSelect';
import { SelectFilterGroupHeader } from './SelectFilterGroupHeader';

type SelectFilterWrapperProps = {
  title: string;
  value: any;
  options: any[];
  label: string;
  placeholder: string;
  onChange: (value: any) => void;
  disabled?: boolean;
  optionGroupLabel?: string;
  optionGroupChildren?: string;
  optionGroupIdentify?: string;
  dataKey?: string;
  className?: string;
  sortable?: boolean;
  onSort?: (item: any) => void;
};

export const SelectFilterWrapper = ({
  title,
  value,
  options,
  label,
  placeholder,
  onChange,
  disabled,
  optionGroupLabel,
  optionGroupChildren,
  optionGroupIdentify,
  dataKey,
  className,
  sortable = false,
  onSort,
}: SelectFilterWrapperProps) => {
  const [isSortingFilter, setIsSortingFilter] = useState(false);

  const handleIsSortingItem = useCallback(() => {
    setIsSortingFilter((prev) => !prev);
  }, []);

  return (
    <div className='w-full pb-2'>
      <div className='flex justify-between items-center'>
        <label htmlFor={title} className={className || 'font-bold text-sm text-kelp py-2 block'}>
          {title}
        </label>
        {env.APP_FEATURE_FLAGS.IS_TO_SORT_FILTERS && sortable && value && value.length > 1 && (
          <span className='text-sm cursor-pointer hover:underline' onClick={handleIsSortingItem}>
            {isSortingFilter ? 'Filter' : 'Sort items '}
          </span>
        )}
      </div>

      {!env.APP_FEATURE_FLAGS.IS_TO_SORT_FILTERS || !isSortingFilter ? (
        <CustomSelect
          id={title}
          className='w-full p-0 m-0'
          filter
          resetFilterOnHide
          value={value}
          options={options}
          inputId='self'
          onChange={(e) => {
            e.stopPropagation();
            onChange(e.value);

            const input = document.querySelector(
              'body > div.p-multiselect-panel.p-component.p-ripple-disabled.p-connected-overlay-enter-done > div.p-multiselect-header > div.p-multiselect-filter-container > input'
            ) as HTMLInputElement;

            if (input) {
              input.focus();
            }
          }}
          optionLabel={label}
          placeholder={placeholder}
          display='chip'
          disabled={disabled}
          optionGroupLabel={optionGroupLabel}
          optionGroupChildren={optionGroupChildren}
          dataKey={optionGroupIdentify || dataKey}
          itemClassName={optionGroupChildren ? 'pl-6' : ''}
          optionGroupTemplate={(groupProps) => (
            <SelectFilterGroupHeader
              {...groupProps}
              selectedState={value}
              optionGroupIdentify={optionGroupIdentify}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      ) : (
        <CustomSelectDnd items={value} onSortOptions={onSort!} />
      )}
    </div>
  );
};
