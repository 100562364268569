import { useGoogleAuth } from '@/hooks';
import { env } from '@/utils/constants';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Sidebar } from 'primereact/sidebar';

type Props = {
  isVisible: boolean;
  onHide: VoidFunction;
  hidePatientName?: boolean;
  showAthenaAppointments?: boolean;
  showGoogleCalendarEvents?: boolean;
  showFullCalendar?: boolean;
  showPatientBackgroundEvents?: boolean;
  showUnconfirmedAppointments?: boolean;
  showEmptyRooms?: boolean;
  isViewingDefaultView: boolean;
  onHidePatientName?: (value: boolean) => void;
  onShowAthenaAppointments?: (value: boolean) => void;
  onShowGoogleCalendarEvents?: (value: boolean) => void;
  onShowFullCalendar?: (value: boolean) => void;
  onShowPatientBackgroundEvents?: (value: boolean) => void;
  onShowUnconfirmedAppointments?: (value: boolean) => void;
  onShowEmptyRooms?: (value: boolean) => void;
};

export const CalendarViewSettingsDrawer = ({
  isVisible,
  onHide,
  hidePatientName = false,
  showAthenaAppointments = false,
  showGoogleCalendarEvents = false,
  showFullCalendar = false,
  showPatientBackgroundEvents = true,
  showUnconfirmedAppointments = true,
  showEmptyRooms = true,
  isViewingDefaultView,
  onHidePatientName = () => {},
  onShowAthenaAppointments = () => {},
  onShowGoogleCalendarEvents = () => {},
  onShowFullCalendar = () => {},
  onShowPatientBackgroundEvents = () => {},
  onShowUnconfirmedAppointments = () => {},
  onShowEmptyRooms = () => {},
}: Props) => {
  const { isLoggedInToGoogle } = useGoogleAuth();

  return (
    <Sidebar
      visible={isVisible}
      position='right'
      onHide={onHide}
      showCloseIcon={false}
      className='w-[500px]'
    >
      <header className='flex items-center justify-between py-3 border-b border-gray-300'>
        <h1 className='text-moss text-xl font-bold'>Calendar View Settings</h1>
        <Button
          icon='pi pi-times'
          rounded
          text
          aria-label='close'
          onClick={onHide}
          className='text-gray-400'
        />
      </header>

      <div className='py-4 flex flex-col gap-4'>
        <SettingItem
          label='Hide patient name'
          checked={hidePatientName}
          onClick={onHidePatientName}
        />
        {(isLoggedInToGoogle || env.APP_FEATURE_FLAGS.IS_TO_REMOVE_CONNECT_WITH_GOOGLE_BUTTON) && (
          <SettingItem
            label='Show Google Calendar events'
            checked={showGoogleCalendarEvents!}
            onClick={onShowGoogleCalendarEvents}
          />
        )}
        <SettingItem
          label='Show Athena appointments'
          checked={showAthenaAppointments}
          onClick={onShowAthenaAppointments}
        />
        <SettingItem
          label='Full calendar'
          checked={showFullCalendar}
          onClick={onShowFullCalendar}
        />
        <SettingItem
          label='Show patient visits background'
          checked={showPatientBackgroundEvents}
          onClick={onShowPatientBackgroundEvents}
        />
        <SettingItem
          label='Show unconfirmed appointments'
          checked={showUnconfirmedAppointments}
          onClick={onShowUnconfirmedAppointments}
        />
        <SettingItem
          label='Show empty rooms'
          checked={isViewingDefaultView ? showEmptyRooms : true}
          onClick={onShowEmptyRooms}
          disabled={!isViewingDefaultView}
        />
      </div>
    </Sidebar>
  );
};

function SettingItem({
  label,
  checked,
  onClick,
  disabled,
}: {
  label: string;
  checked: boolean;
  onClick: (checked: boolean) => void;
  disabled?: boolean;
}) {
  return (
    <div className='flex items-center justify-between'>
      <p className='text-sm'>{label}</p>
      <InputSwitch
        className='ml-2'
        checked={checked}
        onChange={(e) => onClick(Boolean(e.value))}
        disabled={disabled}
      />
    </div>
  );
}
