import { Cmo, SchedulerView } from '@/@types';

export const APPOINTMENT_TYPE_CLASSNAME = {
  consult: [303, 367, 221, 365, 284, 282, 364, 283],
  scan: [121, 123, 128, 3, 124, 122],
  echo: [129, 6, 5],
  ultrasound: [127],
  neuro: [184],
  googleEvent: [0],
};

export const CMOS: Cmo[] = [
  {
    resourceTitle: 'Jahangir Rahman - POD',
    resourceLabel: 'Jahangir Rahman',
    resourceId: 'cmo-22',
  },
  {
    resourceTitle: 'Edward Greaney - POD',
    resourceLabel: 'Edward Greaney',
    resourceId: 'cmo-11',
  },
  {
    resourceTitle: 'Jennifer McGugins-Hill - POD',
    resourceLabel: 'Jennifer McGugins-Hill',
    resourceId: 'cmo-23',
  },
  {
    resourceTitle: 'Cory Kercher - POD',
    resourceLabel: 'Cory Kercher',
    resourceId: 'cmo-35',
  },
  {
    resourceTitle: 'Hina Talib - POD',
    resourceLabel: 'Hina Talib',
    resourceId: 'cmo-16',
  },
  {
    resourceTitle: 'Yadiera Brown - POD',
    resourceLabel: 'Yadiera Brown',
    resourceId: 'cmo-38',
  },
  {
    resourceTitle: 'Kelly Fradin - POD',
    resourceLabel: 'Kelly Fradin',
    resourceId: 'cmo-15',
  },
  {
    resourceTitle: 'Paul Lee - POD',
    resourceLabel: 'Paul Lee',
    resourceId: 'cmo-37',
  },
  {
    resourceTitle: 'Amish Nishawala - POD',
    resourceLabel: 'Amish Nishawala',
    resourceId: 'cmo-61',
  },
  {
    resourceTitle: 'Paul Fenyves - POD',
    resourceLabel: 'Paul Fenyves',
    resourceId: 'cmo-93',
  },
  {
    resourceTitle: 'Gabrielle Page-Wilson - POD',
    resourceLabel: 'Gabrielle Page-Wilson',
    resourceId: 'cmo-85',
  },
  {
    resourceTitle: 'Celina Moore - POD',
    resourceLabel: 'Celina Moore',
    resourceId: 'cmo-88',
  },
  {
    resourceTitle: 'Laura Balda - POD',
    resourceLabel: 'Laura Balda',
    resourceId: 'cmo-90',
  },
  {
    resourceTitle: 'Rony Shimony - POD',
    resourceLabel: 'Rony Shimony',
    resourceId: 'cmo-26',
  },
  {
    resourceTitle: 'Nieca Goldberg - POD',
    resourceLabel: 'Nieca Goldberg',
    resourceId: 'cmo-7',
  },
  {
    resourceTitle: 'Caleb Moore - POD',
    resourceLabel: 'Caleb Moore',
    resourceId: 'cmo-94',
  },
  {
    resourceTitle: 'Daniel Yadegar - POD',
    resourceLabel: 'Daniel Yadegar',
    resourceId: 'cmo-102',
  },
  {
    resourceTitle: 'Robyn Rosenblum - POD',
    resourceLabel: 'Robyn Rosenblum',
    resourceId: 'cmo-66',
  },
  {
    resourceTitle: 'Samira Brown - POD',
    resourceLabel: 'Samira Brown',
    resourceId: 'cmo-119',
  },
];
export const ATHENA_PRACTICE_ID = 24265;

export const SAVED_VIEWS_PRESETS: SchedulerView[] = [
  {
    name: 'Fifth floor',
    locationId: 1,
    id: 1000000000000000,
    filters: {
      rooms: [2, 1, 3, 4, 8, 5, 7, 51, 45],
      cmos: [],
      equipment: [],
      personnel: [],
    },
  },
  {
    name: 'Fourth floor',
    locationId: 1,
    id: 1000000000000001,
    filters: {
      rooms: [40, 41, 42, 43, 39, 50, 52, 53, 54, 55, 46, 47, 48, 49],
      cmos: [],
      equipment: [],
      personnel: [],
    },
  },
];
