import { DateTime } from 'luxon';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Room } from '@/@types';

const now = DateTime.local();

type SelectStartAndRoomProps = {
  roomsOptionsList: Room[];
  handleSubmit: (start: string, rooms: Room) => void;
};

export const SelectStartAndRoom = ({ roomsOptionsList, handleSubmit }: SelectStartAndRoomProps) => {
  const [startTime, setStartTime] = useState(
    now
      .startOf('minute')
      .plus({ minutes: 15 - (now.minute % 15) })
      .toFormat('T')
  );
  const [selectedRoom, setSelectedRoom] = useState<Room>();

  return (
    <div className='p-[var(--space-sm)]'>
      <div className='p-[var(--space-sm)]'>
        <label htmlFor='appointmentTemplatesStart' className='mr-[var(--space-sm)] font-bold'>
          Start
        </label>
        <input
          id='appointmentTemplatesStart'
          name='appointmentTemplatesStart'
          type='time'
          className='rounded-[10px] p-[var(--space-xs)] text-[color:var(--gray-5)] text-[length:var(--font-sm)]'
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
        />
      </div>
      <div className='p-[var(--space-sm)]'>
        <label htmlFor='appointmentTemplatesStart' className='mr-[var(--space-sm)] font-bold'>
          Room
        </label>
        {roomsOptionsList.map((room) => (
          <div key={room.id} className='my-[var(--space-xxs)]'>
            <RadioButton
              inputId={`room-${room.id}`}
              name='appointmentTemplatesRoom'
              value={room.id}
              className='mr-[var(--space-xs)]'
              checked={selectedRoom?.id === room.id}
              onChange={() => setSelectedRoom(room)}
            />
            <label htmlFor='appointmentTemplatesRoom'>{room.name}</label>
          </div>
        ))}
      </div>
      <Button
        label='Confirm'
        size='small'
        className='w-full'
        outlined
        disabled={!selectedRoom || !startTime}
        onClick={() => handleSubmit(startTime, selectedRoom!)}
      />
    </div>
  );
};
