import React, { Component } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import EventContainerWrapper from 'react-big-calendar/lib/addons/dragAndDrop/EventContainerWrapper';
import { DnDContextValue } from './DnDContext';
import { AppointmentWithDate } from '@/@types';

export interface EventContainerWrapperProps {
  accessors: {
    [key: string]: any;
  };
  components: {
    [key: string]: React.ComponentType<any>;
  };
  getters: {
    [key: string]: any;
  };
  localizer: {
    [key: string]: any;
  };
  slotMetrics: {
    [key: string]: any;
  };
  resource: any;
  children: JSX.Element;
}

export type EventMoved = AppointmentWithDate & {
  start: Date;
  height: number | null;
  top: number | null;
};

interface EventContainerWrapperState {
  event: (AppointmentWithDate & { start: Date }) | null;
  dragAndDropEvents: EventMoved[] | null;
  top: number | null;
  height: number | null;
}

declare class IEventContainerWrapper extends Component<
  EventContainerWrapperProps,
  EventContainerWrapperState
> {
  context: DnDContextValue;
  ref: React.RefObject<HTMLDivElement>;
  componentDidMount(): void;
  componentWillUnmount(): void;
  reset(): void;
  update(
    event: any,
    {
      startDate,
      endDate,
      top,
      height,
    }: { startDate: Date; endDate: Date; top: number; height: number }
  ): void;
  handleMove(point: { x: number; y: number }, bounds: ClientRect): void;
  handleResize(point: { x: number; y: number }, bounds: ClientRect): void;
  handleDropFromOutside(point: { x: number; y: number }, boundaryBox: ClientRect): void;
  updateParentScroll(parent: HTMLElement, node: HTMLElement): void;
  _selectable(): void;
  handleInteractionEnd(): void;
  _teardownSelectable(): void;
  renderContent(): JSX.Element;
  render(): JSX.Element;
}

interface IEventContainerWrapperConstructor {
  new (
    props: EventContainerWrapperProps,
    state: EventContainerWrapperState
  ): IEventContainerWrapper;
}
export default EventContainerWrapper as IEventContainerWrapperConstructor;
