import { DateTime } from 'luxon';

const formatMonthYear = (date: DateTime<boolean>): string => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = monthNames[date.month - 1];
  const year = date.year;
  return `${month} ${year}`;
};

function groupByMonthAndYear<T extends Record<string, any>>(
  appointments: T[],
  dateProp: string = 'date'
) {
  const grouped: { [key: string]: T[] } = {};
  appointments.forEach((obj) => {
    const monthYear = formatMonthYear(
      DateTime.fromISO(obj[dateProp], { zone: 'America/New_York' })
    );
    if (!grouped[monthYear]) {
      grouped[monthYear] = [];
    }
    grouped[monthYear].push(obj);
  });

  return grouped;
}

export const ObjectArrayHelper = {
  groupByMonthAndYear,
};
