import { useCallback, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';
import { DateTime } from 'luxon';
import { VisitTypeTemplates, Atria, Room } from '@/@types';
import { useToastContext } from '@/contexts';
import { appointmentsService } from '@/services';
import { SelectActionStep } from './SelectActionStep';
import { SelectTemplateTypeStep } from './SelectTemplateTypeStep';
import { SelectStartAndRoom } from './SelectStartAndRoom';
import { SchedulerHelper } from '@/helpers';

type CreateAppointmentFromTemplateProps = {
  roomsOptionsList: Room[];
  suiteRooms: number[];
  currentDate: string;
  visitTypeTemplates: VisitTypeTemplates[];
  handleCreateNew: () => void;
  onSave: (newAppointments: Atria.Appointment[]) => void;
};

export const CreateAppointmentFromTemplate = ({
  roomsOptionsList,
  suiteRooms,
  currentDate,
  visitTypeTemplates,
  handleCreateNew,
  onSave,
}: CreateAppointmentFromTemplateProps) => {
  const { toast } = useToastContext();
  const [formStep, setFormStep] = useState(1);
  const [selectedTypes, setSelectedTypes] = useState<VisitTypeTemplates[]>([]);

  const suiteRoomsOptionsList = useMemo(() => {
    return roomsOptionsList.filter((room) => suiteRooms.includes(room.id));
  }, [roomsOptionsList, suiteRooms]);

  const selectType = useCallback(() => {
    setFormStep(2);
  }, []);

  const selectStartAndRoom = useCallback((types: VisitTypeTemplates[]) => {
    setSelectedTypes(types);
    setFormStep(3);
  }, []);

  const handleSubmit = useCallback(
    async (start: string, room: Room) => {
      loader.show();
      const events: Atria.CreateAppointment.Body[] = [];
      let currentStartTime = start;

      for (const event of selectedTypes) {
        let eventRooms = [room!];
        if (event.defaultRooms) {
          eventRooms = [
            ...eventRooms,
            ...roomsOptionsList.filter((r) =>
              event.defaultRooms.map((defaultRoom) => defaultRoom.id).includes(r.id)
            ),
          ];
        }

        const formattedStart = DateTime.fromFormat(
          `${currentDate} ${currentStartTime}`,
          'M/d/yy T'
        );

        const relatedRooms = SchedulerHelper.getRelatedNewRooms(
          eventRooms.map((er) => ({ id: er.id, label: er.name })),
          roomsOptionsList.map((er) => ({ id: er.id, label: er.name }))
        );

        events.push({
          date: formattedStart.toUTC().toISO()!,
          duration: event.duration,
          rooms: relatedRooms.map((r) => ({ id: r.id })),
          title: event.name,
          providers: event.defaultEquipment.map((e) => ({
            id: e.id,
            name: e.name,
            type: 'EQUIPMENT',
          })),
          ...(event.defaultType && {
            type: event.defaultType.name,
            typeId: event.defaultType.id,
          }),
        });

        currentStartTime = formattedStart.plus({ minutes: event.duration }).toFormat('T');
      }

      try {
        const createMultipleAppointments: Atria.CreateMultipleAppointments.Body = {
          appointments: events,
        };
        const { data: results } = await appointmentsService.createMultipleAppointments(
          createMultipleAppointments
        );
        if (results) {
          onSave(results);
        }
      } catch (error: any) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: error.response?.data?.message || 'Failed to create appointment',
          life: 3000,
        });
      } finally {
        loader.hide();
      }
    },
    [currentDate, onSave, roomsOptionsList, selectedTypes, toast]
  );

  return (
    <>
      {formStep === 1 && (
        <SelectActionStep handleCreateNew={handleCreateNew} handleCreateFromTemplate={selectType} />
      )}
      {formStep === 2 && (
        <SelectTemplateTypeStep
          visitTypeTemplates={visitTypeTemplates}
          handleSelectTemplateType={selectStartAndRoom}
        />
      )}
      {formStep === 3 && (
        <SelectStartAndRoom roomsOptionsList={suiteRoomsOptionsList} handleSubmit={handleSubmit} />
      )}
    </>
  );
};
