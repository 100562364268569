import { FindAllVisitTypeTemplatesReponse } from '@/@types';
import { api } from '@/api';

function findAll() {
  return api.get<FindAllVisitTypeTemplatesReponse>('/api/v1/visit-types-templates');
}

export const visitTypeTemplatesService = {
  findAll,
};
