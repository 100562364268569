import { Atria } from '@/@types';
import { api } from '@/api';

function findAllEvents(params: Atria.FindAllGoogleEvents.Params) {
  return api.get<Atria.FindAllGoogleEvents.Response>('/api/v1/google/calendar/events', { params });
}

function getToken(body: Atria.GetGoogleToken.Params) {
  return api.post<Atria.GetGoogleToken.Response>('/api/v1/google/auth', body);
}

function getRefreshToken(body: Atria.GetGoogleRefreshToken.Params) {
  return api.post<Atria.GetGoogleRefreshToken.Response>('/api/v1/google/auth/refresh-token', body);
}

export const googleService = {
  findAllEvents,
  getToken,
  getRefreshToken,
};
