import { useSearchParams } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { NotAuthorized } from '../NotAuthorizedPage';
import { useEffect } from 'react';

export const LoginCallbackPage = () => {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (!searchParams.get('code')) {
      window.location.href = '/';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LoginCallback
      errorComponent={NotAuthorized}
      loadingElement={<div className='loadingFeedback'></div>}
    />
  );
};
