import { StringHelper } from '../stringHelper';

const exceptionTypes = ['P&M'];
const getExceptionTypeToUpper = (type: string) => {
  return exceptionTypes.includes(type) && type;
};

const groupOptions = <T>(options: T[], { groupByField }: { groupByField: keyof T }) => {
  return Object.values(
    options.reduce(
      (groups, option) => {
        const type = (option[groupByField] as string) || 'Other';
        if (!groups[type]) {
          groups[type] = {
            label: getExceptionTypeToUpper(type) || StringHelper.toUpCamelCase(type),
            options: [],
          };
        }
        groups[type].options.push({ ...option });
        return groups;
      },
      {} as {
        [key: string]: { label: string; options: T[] };
      }
    )
  );
};

export const SchedulerFilterHelper = {
  groupOptions,
};
