import { Outlet } from 'react-router-dom';
import { ProvidersContext } from '@/hooks';

export function AppointmentsContexts() {
  return (
    <ProvidersContext>
      <Outlet />
    </ProvidersContext>
  );
}
