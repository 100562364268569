export type Room = {
  id: number;
  name: string;
  isSuite?: boolean;
  defaultView: boolean;
  displayOrder: number;
  type: RoomType | null;
  location: {
    id: number;
    name: string;
  };
};

export enum RoomType {
  EXAM_ROOM = 'EXAM_ROOM',
  IMAGING = 'IMAGING',
  SUITE = 'SUITE',
  CARDIAC = 'CARDIAC',
  P_AND_M = 'P&M',
  PEDS = 'PEDS',
}

export enum RoomName {
  Hudson = 'Hudson',
  Bond = 'Bond',
  Crosby = 'Crosby',
  LeRoy = 'LeRoy',
  Mercer = 'Mercer',
  Bleecker = 'Bleecker',
  Broadway = 'Broadway',
}
