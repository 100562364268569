import { useCallback, useState } from 'react';
import { loader } from 'react-global-loader';

import { GoogleEventsWithDate, GoogleEventProvider, Origin, Resources } from '@/@types';
import { useToastContext } from '@/contexts';
import { LocalStorageHelper, LocalStorageKeys } from '@/helpers';
import { googleService } from '@/services';
import { env } from '@/utils/constants';

export function useGoogleCalendar() {
  const { toast } = useToastContext();
  const [googleCalendarAppointments, setGoogleCalendarAppointments] = useState<
    GoogleEventsWithDate[]
  >([]);
  const [eventsOOOAndAllDay, setEventsOOOAndAllDay] = useState<GoogleEventProvider[]>([]);

  const getGoogleCalendarEventsAsResources = useCallback(
    async (
      currentDate: string,
      providersIds: (number | string)[]
    ): Promise<[GoogleEventsWithDate[], GoogleEventProvider[]] | []> => {
      try {
        const { data } = await googleService.findAllEvents({
          day: currentDate,
          providers: JSON.stringify(providersIds),
          refreshToken: LocalStorageHelper.getItem(LocalStorageKeys.GoogleRefreshToken) || '',
        });

        const { events = [], eventsOOOAndAllDay: eventsOOO = [] } = data;

        return [
          events.map((event) => ({
            ...event,
            resourceId: `provider-${event.providerId}`,
            date: new Date(event.start),
            end: new Date(event.end),
            origin: Origin.Google,
          })),
          eventsOOO,
        ];
      } catch {
        toast?.current?.show({
          severity: 'error',
          summary: 'Info Message',
          detail: 'Unable to load google appointments',
          life: 3000,
        });
        return [];
      }
    },
    [toast]
  );

  const findProvidersEventsOnGoogleCalendar = useCallback(
    async (currentDate: string, resources: Resources[]) => {
      loader.show();
      const resourcesIds = resources
        .filter((resource) => resource.email)
        .map((e) => Number(e.resourceId.replace('provider-', '')));
      const isGoogleAuthenticated = !!LocalStorageHelper.getItem(
        LocalStorageKeys.GoogleRefreshToken
      );
      let googleCalendarAppts: GoogleEventsWithDate[] = [];
      if (isGoogleAuthenticated || env.APP_FEATURE_FLAGS.IS_TO_REMOVE_CONNECT_WITH_GOOGLE_BUTTON) {
        const [events, eventsOOO] = await getGoogleCalendarEventsAsResources(
          currentDate,
          resourcesIds
        );

        googleCalendarAppts = events || [];

        setEventsOOOAndAllDay(eventsOOO || []);
      }
      setGoogleCalendarAppointments(googleCalendarAppts);
      loader.hide();
    },
    [getGoogleCalendarEventsAsResources]
  );

  return {
    eventsOOOAndAllDay,
    googleCalendarAppointments,
    findProvidersEventsOnGoogleCalendar,
  };
}
