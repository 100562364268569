import { AppointmentWithDate } from '@/@types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Tooltip } from 'primereact/tooltip';
import { useMemo } from 'react';

type CalendarEventsControlProps = {
  events: AppointmentWithDate[];
  onClear: VoidFunction;
  onEdit: VoidFunction;
  onSelectAllSameColumn: VoidFunction;
  onDuplicate: VoidFunction;
  onDelete: VoidFunction;
};

export const CalendarEventsControl = ({
  events,
  onClear,
  onEdit,
  onSelectAllSameColumn,
  onDuplicate,
  onDelete,
}: CalendarEventsControlProps) => {
  const isVisible = useMemo(() => events.length > 0, [events]);

  return (
    <motion.div
      className='absolute left-0 top-0 w-full'
      animate={isVisible ? 'visible' : 'invisible'}
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, translateY: -50 },
        invisible: { opacity: 0, translateY: 0 },
      }}
    >
      <div className='w-full px-2 pt-2 pb-4 flex items-center justify-start gap-4 rounded-tl-lg rounded-tr-lg bg-gray-200 text-gray-700'>
        <div className='flex items-center justify-center gap-2'>
          <ControlButton
            onClick={onClear}
            tooltipId='clear-selection'
            tooltip='Clear selection'
            icon='pi-times'
          />
          <span className='font-bold text-sm'>
            {events.length} appointment{events.length > 1 && 's'} selected
          </span>
        </div>
        <ControlButton
          onClick={onSelectAllSameColumn}
          tooltipId='select-all-same-column'
          tooltip='Select all from the same column'
          icon='pi-check-square'
        />
        <ControlButton
          onClick={onEdit}
          tooltipId='edit-selection'
          tooltip='Edit'
          icon='pi-pencil'
        />
        <ControlButton
          onClick={onDelete}
          tooltipId='delete-selection'
          tooltip='Delete selected appointment'
          icon='pi-trash'
        />
        <ControlButton
          onClick={onDuplicate}
          tooltipId='duplicate-selection'
          tooltip='Duplicate'
          icon='pi-copy'
        />
      </div>
    </motion.div>
  );
};

function ControlButton({
  onClick,
  tooltipId,
  tooltip,
  icon,
}: {
  onClick: VoidFunction;
  tooltipId: string;
  tooltip: string;
  icon: string;
}) {
  return (
    <>
      <Tooltip target={`.${tooltipId}`} className='text-xs' />
      <button
        onClick={onClick}
        className={classNames(
          'cursor-pointer transition-all hover:bg-black/5 active:ring-4 ring-kelp/30 shadow-black/25 px-2 py-1 rounded-full delete-selection',
          tooltipId
        )}
        data-pr-tooltip={tooltip}
        data-pr-position='bottom'
      >
        <i className={classNames('pi text-gray-700', icon)}></i>
      </button>
    </>
  );
}
