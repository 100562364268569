import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Location } from '@/@types';
import AtriaWord from '@/assets/atria-word-stone.svg';
import { useGoogleAuth } from '@/hooks';
import SingOut from '../SignOut/SignOut';
import { env } from '@/utils/constants';

type Props = {
  locationOptions?: Location[];
  regionSelected?: number;
  changeRegion?: (regionId: number) => void;
  onMenuToggle?: VoidFunction;
};

export const Header = ({
  locationOptions = [],
  regionSelected,
  changeRegion,
  onMenuToggle,
}: Props) => {
  const location = useLocation();
  const accessDenied = location.search.includes('access_denied');
  const { isLoggedInToGoogle, googleLogin, googleLogout } = useGoogleAuth();

  const onLocationChange = useCallback(
    (event: DropdownChangeEvent) => {
      const regionId = event.target.value;
      if (changeRegion) {
        changeRegion(regionId);
      }
    },
    [changeRegion]
  );

  return (
    <div className='h-[64px] p-[var(--space-regular)] bg-[var(--fern)] flex justify-between items-center'>
      <div className='flex items-center'>
        {onMenuToggle && <Button icon='pi pi-bars' onClick={onMenuToggle} />}
        <img src={AtriaWord} alt='Atria Logo' className='h-[32px] mx-4' />
        {!!locationOptions.length && (
          <Dropdown
            className='md:ml-5 ml-0 bg-transparent border hover:border-white h-11 flex items-center
          [&_*]:text-white [&_.p-dropdown-label]:pr-0 [&_*]:font-bold [&_*]:text-sm [&_.p-dropdown-trigger]:w-10'
            options={locationOptions}
            value={regionSelected}
            onChange={onLocationChange}
            dropdownIcon='pi pi-globe'
            optionLabel='name'
            optionValue='id'
            filter
          />
        )}
      </div>
      {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_APPOINTMENT_SEARCH_LINK && onMenuToggle && (
        <Link to='/appointments' className='p-button text-white border-white h-11 px-20'>
          Search Appointments
          <i className='pi pi-search ml-2' />
        </Link>
      )}
      {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_APPOINTMENT_SEARCH_LINK && !onMenuToggle && (
        <Link to='/' className='p-button text-white border-white h-11 px-20'>
          Scheduler
          <i className='pi pi-calendar ml-2' />
        </Link>
      )}

      <div className='flex h-11'>
        <div className='flex mr-2 items-center'>
          {!accessDenied && !env.APP_FEATURE_FLAGS.IS_TO_REMOVE_CONNECT_WITH_GOOGLE_BUTTON && (
            <>
              {isLoggedInToGoogle ? (
                <Button
                  className='text-white border-white h-full'
                  label='Disconnect Google Account'
                  icon='pi pi-google'
                  size='small'
                  outlined
                  onClick={googleLogout}
                />
              ) : (
                <Button
                  className='text-white border-white h-full'
                  label='Connect Google Account'
                  icon='pi pi-google'
                  size='small'
                  outlined
                  onClick={googleLogin}
                />
              )}
            </>
          )}
        </div>
        <div className='flex'>
          <SingOut />
        </div>
      </div>
    </div>
  );
};
