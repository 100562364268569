import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import TimeGridEvent from 'react-big-calendar/lib/TimeGridEvent';

interface TimeGridEventProps {
  style: {
    height: number | null;
    top: number | null;
    width: number;
    xOffset?: number;
  };
  className?: string;
  event: any;
  accessors: {
    title: (event: any) => string;
    tooltip: (event: any) => string | undefined;
    start: (event: any) => Date;
    end: (event: any) => Date;
  };
  rtl?: boolean;
  selected?: boolean;
  label: string;
  continuesPrior: boolean;
  continuesAfter: boolean;
  getters: {
    eventProp: (
      event: any,
      start: Date,
      end: Date,
      selected: boolean
    ) => {
      style: React.CSSProperties;
      className?: string;
    };
  };
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDoubleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  isBackgroundEvent?: boolean;
  components: {
    event: React.ComponentType<{ event: any; title: string }>;
    eventWrapper: React.ComponentType<any>;
  };
}

export default TimeGridEvent as React.ComponentType<TimeGridEventProps>;
