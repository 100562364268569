import { useCallback } from 'react';

import { useLocalStorage } from './useLocalStorage';

export type SchedulerViewSettingsOptions = {
  hidePatientName: boolean;
  showFullCalendar: boolean;
  showAthenaAppointments: boolean;
  showAtriaAppointments: boolean;
  showGoogleCalendarEvents: boolean;
  showUnconfirmedAppointments: boolean;
  showPatientBackgroundEvents: boolean;
  showEmptyRooms: boolean;
};

export function useSchedulerViewSettings() {
  const [filters, setFilter] = useLocalStorage<SchedulerViewSettingsOptions>('schedulerOptions', {
    hidePatientName: false,
    showFullCalendar: false,
    showAthenaAppointments: true,
    showAtriaAppointments: true,
    showGoogleCalendarEvents: true,
    showUnconfirmedAppointments: true,
    showPatientBackgroundEvents: true,
    showEmptyRooms: true,
  });
  const setFilterItem = useCallback(
    (key: keyof SchedulerViewSettingsOptions, value: boolean) => {
      return setFilter((prev) => ({ ...prev, [key]: value }));
    },
    [setFilter]
  );

  const onShowFullCalendar = useCallback(
    (checked: boolean) => {
      setFilterItem('showFullCalendar', checked);
    },
    [setFilterItem]
  );

  const onHidePatientName = useCallback(
    (checked: boolean) => {
      setFilterItem('hidePatientName', checked);
    },
    [setFilterItem]
  );

  const onShowGoogleCalendarEvents = useCallback(
    (checked: boolean) => {
      setFilterItem('showGoogleCalendarEvents', checked);
    },
    [setFilterItem]
  );

  const onShowAthenaAppointments = useCallback(
    (checked: boolean) => {
      setFilterItem('showAthenaAppointments', checked);
    },
    [setFilterItem]
  );

  const onShowAtriaAppointments = useCallback(
    (checked: boolean) => {
      setFilterItem('showAtriaAppointments', checked);
    },
    [setFilterItem]
  );

  const onShowPatientBackgroundEvents = useCallback(
    (checked: boolean) => {
      setFilterItem('showPatientBackgroundEvents', checked);
    },
    [setFilterItem]
  );

  const onShowUnconfirmedAppointments = useCallback(
    (checked: boolean) => {
      setFilterItem('showUnconfirmedAppointments', checked);
    },
    [setFilterItem]
  );

  const onShowEmptyRooms = useCallback(
    (checked: boolean) => {
      setFilterItem('showEmptyRooms', checked);
    },
    [setFilterItem]
  );

  return {
    ...filters,
    onShowFullCalendar,
    onHidePatientName,
    onShowGoogleCalendarEvents,
    onShowAthenaAppointments,
    onShowAtriaAppointments,
    onShowPatientBackgroundEvents,
    onShowUnconfirmedAppointments,
    onShowEmptyRooms,
  };
}
