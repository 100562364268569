import { createContext, useCallback, useContext, useState } from 'react';

import { AthenaDepartment } from '@/@types/atriaService';
import { Region } from '@/@types/region';
import { locationService } from '@/services';
import { useLocalStorage } from './useLocalStorage';

type Type = {
  locations: Region[];
  selectedRegion: Region | null;
  findAllLocations: () => Promise<void>;
  getAthenaDepartments: () => AthenaDepartment[];
  getAthenaDepartmentDefault: () => AthenaDepartment | undefined;
  getAthenaDepartmentNameById: (athenaDepartmentId: number) => string | undefined;
  setSelectedRegion: (location: Region | null) => void;
};

const Context = createContext<Type>({
  locations: [],
  selectedRegion: null,
  findAllLocations: async () => {},
  getAthenaDepartments: () => [],
  getAthenaDepartmentDefault: () => undefined,
  getAthenaDepartmentNameById: () => undefined,
  setSelectedRegion: async () => {},
});

type Props = {
  children?: React.ReactNode;
};

export function LocationContext({ children }: Props) {
  const [locations, setLocations] = useState<Region[]>([]);
  const [selectedRegion, setSelectedRegion] = useLocalStorage<Region | null>('location', null);

  const findAllLocations = useCallback(async () => {
    const { data } = await locationService.findAll();
    setLocations(data);
  }, []);

  function getAthenaDepartments() {
    return selectedRegion?.athenaDepartments || [];
  }

  function getAthenaDepartmentDefault(): AthenaDepartment | undefined {
    return selectedRegion?.athenaDepartments?.[0] || undefined;
  }

  const getAthenaDepartmentNameById = useCallback(
    (athenaDepartmentId: number): string | undefined => {
      return selectedRegion?.athenaDepartments?.find(
        (department) => department.departmentId === athenaDepartmentId
      )?.name;
    },
    [selectedRegion]
  );

  return (
    <Context.Provider
      value={{
        locations,
        selectedRegion,
        setSelectedRegion,
        findAllLocations,
        getAthenaDepartments,
        getAthenaDepartmentDefault,
        getAthenaDepartmentNameById,
      }}
    >
      {children}
    </Context.Provider>
  );
}

// TODO: move the file to the context folder
export const useLocation = () => useContext(Context);
