import { useMemo } from 'react';
import { AppointmentItem } from './AppointmentItem';
import { Atria } from '@/@types';
import { ObjectArrayHelper } from '@/helpers';
type AppointmentListProps = {
  appointmentList: Atria.Appointment[] | null;
  classNames?: string;
};
import styles from './CustomScroll.module.css';

export const AppointmentList = ({ appointmentList, classNames = '' }: AppointmentListProps) => {
  const groupedAppointmentsHash = useMemo(
    () => ObjectArrayHelper.groupByMonthAndYear(appointmentList || []),
    [appointmentList]
  );
  const groupedAppointments = useMemo(
    () =>
      Object.keys(groupedAppointmentsHash)
        .map((month) => ({
          month,
          appointments: groupedAppointmentsHash[month],
        }))
        .sort(
          (a, b) =>
            new Date(b.appointments[0].date).valueOf() - new Date(a.appointments[0].date).valueOf()
        ),
    [groupedAppointmentsHash]
  );
  return (
    <div
      className={`min-h-[450px] max-h-[94%] overflow-y-auto p-5 pt-0 pr-3 mr-4
        bg-white rounded-lg ${!groupedAppointments.length ? 'content-center' : ''}
        ${styles.customScroll} ${classNames}`}
    >
      {!groupedAppointments.length && (
        <p className='text-center'>
          {appointmentList === null
            ? 'Use the filters in the left side'
            : 'No appointments found for the filters selected'}
        </p>
      )}
      {!!groupedAppointments.length &&
        groupedAppointments.map(({ month, appointments }) => (
          <div className='mt-4' key={month}>
            <h2 className='pb-2 pl-2 text-md'>{month}</h2>
            <ul>
              {appointments.map((appointment) => (
                <AppointmentItem key={appointment.id} item={appointment}></AppointmentItem>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};
