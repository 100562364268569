export function CloseButtonImage({ fill = '#3C4E3D', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24px'
      viewBox='0 -960 960 960'
      width='24px'
      {...props}
    >
      <path
        d='m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z'
        style={{ fill }}
      />
    </svg>
  );
}
