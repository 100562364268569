import React from 'react';
import EventContainerWrapper, { EventMoved } from '../dragAndDrop/EventContainerWrapper';
import TimeGridEvent from '../../TimeGridEvent';
import { DnDContextValue } from '../dragAndDrop/DnDContext';
import { AppointmentWithDate, PatientVisit } from '@/@types';
import getStyledEvents from '../../utils/layout-algorithms/no-overlap';
import { SchedulerConstants } from '@/utils/constants';

type DataObject = { [key: string]: any };

function accessor(data: any, field: string | ((data: any) => any)): any {
  let value: any = null;

  if (typeof field === 'function') {
    value = field(data);
  } else if (
    typeof field === 'string' &&
    typeof data === 'object' &&
    data !== null &&
    field in data
  ) {
    value = data[field];
  }

  return value;
}

const wrapAccessor = (acc: (data: any) => any) => (data: any) => accessor(data, acc);

interface DragAccessors {
  start: (data: any) => any;
  end: (data: any) => any;
}
const dragAccessors: DragAccessors = {
  start: wrapAccessor(({ start }: DataObject) => start),
  end: wrapAccessor(({ end }: DataObject) => end),
};

class CustomEventContainerWrapper extends EventContainerWrapper {
  private handlePositions(
    eventMoved: EventMoved,
    eventMovedOriginal: AppointmentWithDate,
    eventsToProcess: AppointmentWithDate[],
    config: { min?: Date; max?: Date } = {}
  ) {
    const eventMovedSize = eventMoved.start.getTime() - eventMoved.end.getTime();
    const startOfDayMoved = config.min!;

    const eventsToShow: EventMoved[] = eventsToProcess.map((event) => {
      if (!eventMoved.height || (event.id && event.id == eventMoved.id)) {
        return {
          ...eventMoved,
          top: eventMoved.top,
          height: eventMoved.height,
          date: eventMoved.start,
          start: eventMoved.start,
        };
      }

      const distanceEventFromEventMovedOriginal =
        event.date.getTime() - eventMovedOriginal.date.getTime();
      const eventSize = event.date.getTime() - event.end.getTime();
      const start = new Date(eventMoved.start.getTime() + distanceEventFromEventMovedOriginal);
      const end = new Date(start.getTime() + Math.abs(eventSize));

      const height = (eventMoved.height * eventSize) / eventMovedSize;
      const distanceEventFromStartOfDay = start.getTime() - startOfDayMoved.getTime();

      const top = (eventMoved.height! / Math.abs(eventMovedSize)) * distanceEventFromStartOfDay;

      return {
        ...event,
        start: start,
        date: start,
        end,
        height,
        top,
      };
    });
    const endOfDayDate = config.max!;
    const firstEvent = eventsToShow.sort((a, b) => a.start.getTime() - b.start.getTime())[0];
    const lastEvent = eventsToShow.sort((a, b) => b.end.getTime() - a.end.getTime())[0];
    const finalEventsToShow = this.handleLimitBottom(
      this.handleLimitTop(eventsToShow, firstEvent, startOfDayMoved),
      lastEvent,
      endOfDayDate
    );

    return finalEventsToShow;
  }
  private handleLimitTop(
    eventsToShow: EventMoved[],
    firstEvent: EventMoved,
    startOfDayMoved: Date
  ) {
    if (firstEvent.top! > 0) {
      return eventsToShow;
    }
    return eventsToShow.map((event) => {
      const eventSize = Math.abs(event.date.getTime() - event.end.getTime());
      const top = event.top! + Math.abs(firstEvent.top!);

      const newDistanceToStartOfTheDay = Math.ceil((eventSize / event.height!) * (top || 0));

      const start = new Date(startOfDayMoved.getTime() + newDistanceToStartOfTheDay);
      const end = new Date(start.getTime() + Math.abs(eventSize));
      return {
        ...event,
        date: start,
        start,
        end,
        top,
      };
    });
  }
  private handleLimitBottom(eventsToShow: EventMoved[], lastEvent: EventMoved, endOfDayDate: Date) {
    if (lastEvent.end < endOfDayDate) {
      return eventsToShow;
    }
    const distanceAfterEndOfDay = Math.abs(lastEvent.end.getTime() - endOfDayDate.getTime());
    return eventsToShow.map((event) => {
      const eventSize = Math.abs(event.date.getTime() - event.end.getTime());
      const start = new Date(event.date.getTime() - distanceAfterEndOfDay);
      const end = new Date(start.getTime() + eventSize);
      const { top, height } = this.props.slotMetrics.getRange(start, end, false, false);
      return {
        ...event,
        date: start,
        height,
        start,
        end,
        top,
      };
    });
  }
  private processEventsToShow = (eventMoved: EventMoved) => {
    const { draggable } = this.context as DnDContextValue;

    const eventMovedOriginal: AppointmentWithDate = draggable.events.find(
      (event) => event.id == eventMoved.id
    )!;

    const eventMovedIsPartOfSelectedEvents = draggable?.selectedEvents?.find(
      (event) => event.id === eventMoved.id
    );
    const eventsToProcess =
      eventMovedIsPartOfSelectedEvents && eventMoved.height
        ? draggable?.selectedEvents
        : [eventMovedOriginal];

    return this.handlePositions(eventMoved, eventMovedOriginal, eventsToProcess, {
      min: draggable.min,
      max: draggable.max,
    });
  };

  update(
    event: AppointmentWithDate,
    {
      startDate,
      endDate,
      top,
      height,
    }: { startDate: Date; endDate: Date; top: number; height: number }
  ) {
    const { event: lastEvent } = this.state;
    if (lastEvent && startDate === lastEvent?.start && endDate === lastEvent.end) {
      return;
    }
    const newEventPayload = { ...event, start: startDate, end: endDate };
    const dragAndDropEvents = event
      ? this.processEventsToShow({ ...newEventPayload, height, top })
      : null;
    this.setState({
      top,
      height,
      event: { ...event, start: startDate, end: endDate },
      dragAndDropEvents,
    });
  }

  handleInteractionEnd = () => {
    const { resource } = this.props;
    const { event, dragAndDropEvents } = this.state;
    this.reset();

    this.context.draggable.onEnd({
      start: event?.start,
      end: event?.end,
      resourceId: resource,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      events: dragAndDropEvents?.map(({ height, top, ...eventContent }) => eventContent),
    });
  };

  private extractValues(calcStr: string) {
    const regex = /calc\(([\d.]+)% ([+-]) (-?\d+)px\)/;
    const matches = calcStr.match(regex);
    if (matches) {
      return {
        percentage: parseFloat(matches[1]),
        pixel: parseFloat(matches[3]),
      };
    }
    return {
      percentage: 0,
      pixel: 0,
    };
  }

  private randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  adjustEventsWithTheBackgroundEventPosition(
    eventsComponents: React.ReactElement[],
    backgroundEventStyle: { width: string; left: number }
  ) {
    const eventsById = eventsComponents.reduce((acc, event) => {
      acc.set(event.props.event.id, event);
      return acc;
    }, new Map());

    const eventsWithStyleFixed = getStyledEvents({
      events: eventsComponents.map(({ props }) => props.event),
      slotMetrics: this.props.slotMetrics,
      accessors: this.props.accessors,
      minimumStartDifference: Math.ceil(
        (SchedulerConstants.step * SchedulerConstants.timeslots) / 2
      ),
      dayLayoutAlgorithm: 'no-overlap',
    });
    const parentWidthValues = this.extractValues(backgroundEventStyle.width);
    const parentXOffsetValue = backgroundEventStyle.left;
    return eventsWithStyleFixed.map((event) => {
      const eventOriginal = eventsById.get((event.event as AppointmentWithDate).id);
      const childrenWidthValues = this.extractValues(event.style.width);
      const childrenXOffsetValue = event.style.left;
      const leftFinal =
        parentXOffsetValue + childrenXOffsetValue / (100 / parentWidthValues.percentage);

      return React.cloneElement(eventOriginal, {
        ...eventOriginal.props,
        style: {
          ...eventOriginal.props.style,
          width: (childrenWidthValues.percentage * parentWidthValues.percentage) / 100,
          left: leftFinal,
          xOffset: leftFinal,
        },
      });
    });
  }

  adjustBackgroundEventsAndEventsPositionsConflict(
    backgroundEvents: React.ReactElement<{
      event: PatientVisit;
      style: {
        left: number;
        width: string;
        xOffset: string;
      };
    }>[],
    events: React.ReactElement<{
      event: AppointmentWithDate;
      style: {
        left: number;
        width: string;
        xOffset: string;
      };
    }>[]
  ) {
    const idOfEventsInsideBackgroundEvents = backgroundEvents.reduce((acc, backgroundEvent) => {
      backgroundEvent.props.event.appointments.forEach((appointment: AppointmentWithDate) => {
        acc.add(appointment.id);
      });
      return acc;
    }, new Set());

    const eventsMapById = events.reduce((acc, event) => {
      acc.set(event.props.event.id, event);
      return acc;
    }, new Map());

    const eventsNotInsideBackGroundEvents = events.filter(
      (event) => !idOfEventsInsideBackgroundEvents.has(event.props.event.id)
    );

    const backgroundEventToUse = backgroundEvents.map((event) => ({
      ...event,
      props: {
        ...event.props,
        event: {
          ...event.props.event,
          randomId: this.randomIntFromInterval(20, 2000000000),
          id: null,
          duration: 0,
        },
      },
    }));

    const eventBackgroundAndEventsNotInEventsBackgroundFixed = getStyledEvents({
      events: [...backgroundEventToUse, ...eventsNotInsideBackGroundEvents].map(
        ({ props }) => props.event
      ),
      slotMetrics: this.props.slotMetrics,
      accessors: this.props.accessors,
      minimumStartDifference: (SchedulerConstants.step * SchedulerConstants.timeslots) / 2,
      dayLayoutAlgorithm: 'no-overlap',
    });
    const backgroundEventsFixed = eventBackgroundAndEventsNotInEventsBackgroundFixed
      .filter(({ event }) => (event as PatientVisit).isVisitEvent)
      .map((event) => {
        const original = backgroundEventToUse.find(
          ({ props }) =>
            props.event.randomId === (event.event as PatientVisit & { randomId: number }).randomId
        );
        return React.cloneElement(original!, {
          ...original?.props,
          style: {
            ...original!.props.style,
            width: event.style.width,
            left: event.style.left,
            xOffset: event.style.xOffset,
          },
        });
      });

    const eventsComponentsWithoutBackgroundEventFixed =
      eventBackgroundAndEventsNotInEventsBackgroundFixed
        .filter(({ event }) => !(event as PatientVisit).isVisitEvent)
        .map((event) => {
          const original = eventsMapById.get((event.event as AppointmentWithDate).id);
          return React.cloneElement(original, {
            ...original?.props,
            style: {
              ...original.props.style,
              width: event?.style.width,
              left: event?.style.left,
              xOffset: event?.style.xOffset,
            },
          });
        });

    const eventsComponentsInsideBackgroundEventFixed =
      eventBackgroundAndEventsNotInEventsBackgroundFixed
        .filter(({ event }) => (event as PatientVisit).isVisitEvent)
        .map((backgroundEvent) => {
          const childrenEventsComponents = (backgroundEvent.event as PatientVisit).appointments
            .map(({ id }) => eventsMapById.get(id))
            .filter((event) => event);
          return this.adjustEventsWithTheBackgroundEventPosition(childrenEventsComponents, {
            left: backgroundEvent.style.left,
            width: backgroundEvent.style.width,
          });
        })
        .flat();
    return {
      eventsFixed: [
        ...eventsComponentsWithoutBackgroundEventFixed,
        ...eventsComponentsInsideBackgroundEventFixed,
      ],
      backgroundEventsFixed,
    };
  }
  renderContent() {
    const { children, accessors, components, getters, slotMetrics } = this.props;
    const [backgroundEvents, events] = children.props.children as React.ReactElement[][];
    const { backgroundEventsFixed, eventsFixed } =
      this.adjustBackgroundEventsAndEventsPositionsConflict(backgroundEvents, events);
    if (!this.state.event && backgroundEventsFixed?.length) {
      return React.cloneElement(children, {
        ...children.props,
        children: [backgroundEventsFixed, eventsFixed],
      });
    }
    if (!this.state.event) {
      return children;
    }
    const eventsToShow = this.state.dragAndDropEvents;
    const childrenOfChildren = backgroundEventsFixed?.length
      ? [backgroundEventsFixed, eventsFixed]
      : children.props.children;
    return React.cloneElement(children, {
      children: (
        <>
          {childrenOfChildren}
          {eventsToShow &&
            eventsToShow.map((event, index) => {
              const startsBeforeDay = slotMetrics.startsBeforeDay(event.start);
              const startsAfterDay = slotMetrics.startsAfterDay(event.end);
              return (
                <TimeGridEvent
                  event={event}
                  label={''}
                  key={index}
                  className='rbc-addons-dnd-drag-preview'
                  style={{ top: event.top, height: event.height, width: 100 }}
                  getters={getters as any}
                  components={components as any}
                  accessors={{ ...accessors, ...dragAccessors } as any}
                  continuesPrior={startsBeforeDay}
                  continuesAfter={startsAfterDay}
                />
              );
            })}
        </>
      ),
    });
  }
}

export default CustomEventContainerWrapper;
