import { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

type CustomSelectDnd = {
  items: any[];
  onSortOptions: (sortedOptions: any[]) => void;
};

export const CustomSelectDnd = ({ items, onSortOptions }: CustomSelectDnd) => {
  const handleDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }

      const reorderedItems = Array.from(items);
      const [removed] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, removed);

      onSortOptions(reorderedItems);
    },
    [items, onSortOptions]
  );

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ listStyleType: 'none', padding: 0, margin: '12px 0' }}
            >
              {items.map((option, index) => (
                <Draggable key={option.resourceId} draggableId={option.resourceId} index={index}>
                  {(providedItem) => (
                    <li
                      ref={providedItem.innerRef}
                      {...providedItem.draggableProps}
                      {...providedItem.dragHandleProps}
                      className='p-2 my-1 border-[1px] border-[#ccc] rounded-md bg-[#f7f7f7] cursor-pointer text-sm flex justify-between items-center'
                      style={{
                        ...providedItem.draggableProps.style,
                      }}
                    >
                      <span>{option.resourceTitle}</span>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
