import { FindAllProvidersResponse } from '@/@types';
import { api } from '@/api';

function findAll() {
  return api.get<FindAllProvidersResponse>('/api/v1/providers');
}

export const providersService = {
  findAll,
};
