import { FindAllRoomsResponse } from '@/@types';
import { api } from '@/api';

function findAll() {
  return api.get<FindAllRoomsResponse>('/api/v1/rooms');
}

export const roomsService = {
  findAll,
};
