import { AppointmentType, AppointmentWithDate, Provider, Room } from '@/@types';
import { AppointmentForm } from '@/components';
import { NewAppointmentInformation } from '@/components/AppointmentForm/AppointmentForm';
import { createContext, useCallback, useContext, useState } from 'react';

type Props = {
  appointmentTypes: AppointmentType[];
  children?: React.ReactNode;
  rooms: Room[];
  providers: Provider[];
};

type AppointmentsFormContextType = {
  onAppointmentCreateClick: (newApptInfo: NewAppointmentInformation) => void;
  onAppointmentEditClick: (
    appointment: AppointmentWithDate | AppointmentWithDate[] | undefined,
    onSubmitCallback: () => void
  ) => void;
  onAppointmentDuplicateClick: (
    appointment: AppointmentWithDate | AppointmentWithDate[] | undefined,
    submitCallback: () => void
  ) => void;
};

const Context = createContext<AppointmentsFormContextType>({
  onAppointmentCreateClick: () => {},
  onAppointmentEditClick: () => {},
  onAppointmentDuplicateClick: () => {},
});

export function AppointmentsFormContext({ appointmentTypes, children, rooms, providers }: Props) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [formMode, setFormMode] = useState<'add' | 'edit' | 'duplicate'>('add');
  const [newAppointmentInformation, setNewAppointmentInformation] =
    useState<NewAppointmentInformation>();
  const [appointmentEditionData, setAppointmentEditionData] = useState<
    AppointmentWithDate | AppointmentWithDate[]
  >();
  const [onSubmitCallback, setOnSubmitCallback] = useState<() => void>();

  const onAppointmentCreateClick = useCallback((newApptInfo: NewAppointmentInformation) => {
    setFormMode('add');
    setNewAppointmentInformation(newApptInfo);
    setShowDrawer(true);
  }, []);

  const onAppointmentEditClick = useCallback(
    (
      appointment: AppointmentWithDate | AppointmentWithDate[] | undefined,
      submitCallback: () => void
    ) => {
      setFormMode('edit');
      setAppointmentEditionData(appointment);
      setOnSubmitCallback(() => submitCallback);
      setShowDrawer(true);
    },
    []
  );

  const onAppointmentDuplicateClick = useCallback(
    (
      appointment: AppointmentWithDate | AppointmentWithDate[] | undefined,
      submitCallback: () => void
    ) => {
      setFormMode('duplicate');
      setAppointmentEditionData(appointment);
      setOnSubmitCallback(() => submitCallback);
      setShowDrawer(true);
    },
    []
  );

  const onAppointmentFormCancel = useCallback(() => {
    setFormMode('add');
    setShowDrawer(false);
  }, []);

  const onSubmit = useCallback(() => {
    if (onSubmitCallback) onSubmitCallback();
    setShowDrawer(false);
  }, [onSubmitCallback]);

  return (
    <Context.Provider
      value={{ onAppointmentCreateClick, onAppointmentEditClick, onAppointmentDuplicateClick }}
    >
      <AppointmentForm
        visible={showDrawer}
        mode={formMode}
        newAppointmentInfo={newAppointmentInformation}
        appointmentTypesList={appointmentTypes}
        rooms={rooms}
        providers={providers}
        handleOnHide={onAppointmentFormCancel}
        handleOnSubmit={onSubmit}
        data={appointmentEditionData}
      />
      {children}
    </Context.Provider>
  );
}

export const useAppointmentsForm = () => useContext(Context);
