import { useOktaAuth } from '@okta/okta-react';
import { authService } from '../../services/authService';
import { Button } from 'primereact/button';

const SingOut = () => {
  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    await authService.deleteCookieAccess();
    await oktaAuth.signOut();
  };

  return (
    <Button
      className='text-white border-white max-[400px]:text-[0px] max-[400px]:mr-0'
      label='Log Out'
      icon='pi pi-sign-out'
      size='small'
      outlined
      onClick={logout}
    />
  );
};

export default SingOut;
