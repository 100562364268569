type EnvType = {
  APP_NODE_ENV: string;
  APP_ATHENA_URL: string;
  APP_SENTRY_DSN: string;
  APP_SOCKET_URL: string;
  APP_FRONT_URL: string;
  APP_BACKEND_URL: string;
  APP_GOOGLE_CLIENT_ID: string;
  APP_OKTA_DOMAIN: string;
  APP_OKTA_CLIENT_ID: string;
  APP_FEATURE_FLAGS: {
    IS_TO_GROUP_ROOMS_FILTER: boolean;
    IS_TO_GROUP_CLINICIAN_FILTER: boolean;
    IS_TO_SHOW_APPOINTMENTS_DELETED_HISTORY: boolean;
    IS_TO_SHOW_APPOINTMENTS_LOG_HISTORY: boolean;
    IS_TO_REMOVE_CONNECT_WITH_GOOGLE_BUTTON: boolean;
    IS_TO_USE_FIXED_TIME_AND_TIMEZONE_ON_DATE_PICKER_CHANGE: boolean;
    IS_TO_SHOW_SEARCH_BY_MRN: boolean;
    IS_TO_ENABLE_APPOINTMENT_SEARCH_LINK: boolean;
    IS_TO_SHOW_APPOINTMENTS_CONFLICTS: boolean;
    IS_TO_SHOW_ATHENA_APPOINTMENT_SYNC: boolean;
    IS_TO_ENABLE_TELEHEALTH_LINK: boolean;
    IS_TO_SORT_FILTERS: boolean;
    IS_TO_ENABLE_UNDO_HISTORY: boolean;
  };
};
declare global {
  interface Window {
    env: EnvType;
  }
}

export const env: EnvType = { ...window.env };

export const SchedulerConstants = {
  step: 15,
  timeslots: 1,
};
