import { DailyNotes } from '@/@types';
import { api } from '@/api';

async function findDailyNotesByDate(date: string, locationId: number) {
  return api.get<DailyNotes | null>('/api/v1/daily-notes', { params: { date, locationId } });
}

async function createOrUpdateDailyNotes(date: string, html: string, locationId: number) {
  return api.post<DailyNotes>('/api/v1/daily-notes', { date, html, locationId });
}

export const dailyNotesService = {
  findDailyNotesByDate,
  createOrUpdateDailyNotes,
};
