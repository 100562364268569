import { HttpStatusCode } from 'axios';
import { useCallback } from 'react';
import { useToastContext } from '@/contexts';
import { dailyNotesService } from '@/services';
import { DateTimeHelper } from '@/helpers';

export function useDailyNotes() {
  const { toast: ref } = useToastContext();

  const findDailyNotesByDate = useCallback(async (date: Date, locationId: number) => {
    const { data } = await dailyNotesService.findDailyNotesByDate(
      DateTimeHelper.returnOnlyDateYYYYmmDD(date),
      locationId
    );
    return data?.html || '';
  }, []);

  const createOrUpdateDailyNotes = useCallback(
    async (date: Date, html: string, locationId: number) => {
      const { status, data } = await dailyNotesService.createOrUpdateDailyNotes(
        DateTimeHelper.returnOnlyDateYYYYmmDD(date),
        html,
        locationId
      );
      if (status === HttpStatusCode.Ok) {
        ref?.current.show({
          severity: 'success',
          summary: 'Saved',
          detail: 'Daily note saved successfully',
          life: 1500,
        });
        return data.html;
      }

      return '';
    },
    [ref]
  );

  return {
    findDailyNotesByDate,
    createOrUpdateDailyNotes,
  };
}
