import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';

import { AppointmentWithDate } from '@/@types';
import { Permission, useAuthContext } from '@/contexts/authContext';
import { useLocation } from '@/hooks';
import { getPatientAthenaChartUrl } from '@/utils';
import { env } from '@/utils/constants';
import { DateTime } from 'luxon';
import { classNames } from 'primereact/utils';
import styles from './AppointmentDetails.module.css';
import { appointmentsService } from '@/services';
import { useCallback, useState } from 'react';
import { useToastContext } from '@/contexts';
import { ConfirmDeleteDialog } from '../ConfirmDeleteDialog';

type AppointmentDetailsProps = {
  visible: boolean;
  onHide: () => void;
  appointment: AppointmentWithDate;
  onDeleteAppointment: (appointment: AppointmentWithDate) => void;
  onEditAppointment: (appointment: AppointmentWithDate) => void;
  onDuplicateAppointment: (appointment: AppointmentWithDate) => void;
  showAppointmentAuditsHistoryDrawer: () => void;
};

export const AppointmentDetails = ({
  visible,
  onHide,
  appointment,
  onDeleteAppointment,
  onEditAppointment,
  onDuplicateAppointment,
  showAppointmentAuditsHistoryDrawer,
}: AppointmentDetailsProps) => {
  const { hasPermission } = useAuthContext();
  const { getAthenaDepartmentNameById } = useLocation();
  const luxonStartDate = DateTime.fromJSDate(appointment.date).toFormat('EEE, MMMM dd - t');
  const luxonEndDate = DateTime.fromJSDate(appointment.end).toFormat('t');
  const updatedByLabel = `Last edited by ${
    appointment.updatedBy
  } on ${appointment.updatedAt.toLocaleDateString()} ${appointment.updatedAt.toLocaleTimeString()}.`;
  const { toast } = useToastContext();
  const [showConfirmAppointmentDelete, setShowConfirmAppointmentDelete] = useState(false);

  const deleteZoomLink = useCallback(async () => {
    try {
      await appointmentsService.deleteConferenceLinkFromAppointment(appointment.id);

      toast?.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Zoom link deleted successfully',
        life: 3000,
      });
    } catch {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to delete zoom link',
        life: 3000,
      });
    } finally {
      onHide();
    }
  }, [appointment.id, onHide, toast]);

  return (
    <Sidebar
      visible={visible}
      onHide={onHide}
      position='right'
      showCloseIcon={false}
      className={styles.container}
      blockScroll={true}
    >
      {appointment && (
        <>
          <header className={styles.header}>
            <h1>{appointment.title}</h1>
            <Button icon='pi pi-times' rounded text aria-label='close' onClick={onHide} />
          </header>
          <main className={styles.content}>
            <div className={styles.row}>
              <i className='pi pi-calendar' />
              <p>
                {luxonStartDate} - {luxonEndDate}
              </p>
            </div>
            <div className={styles.row + ' flex-col items-start'}>
              <div className='flex w-full'>
                <div className={styles.column + ' flex-col items-start'}>
                  <div className='flex items-center'>
                    <i className='pi pi-clock' />
                    <p>
                      <b>Check-in:</b> {appointment.checkIn ? appointment.checkIn : '-'}
                    </p>
                  </div>
                </div>

                <div className={styles.column}>
                  <i className='pi pi-clock' />
                  <p>
                    <b>Check-out:</b> {appointment.checkOut ? appointment.checkOut : '-'}
                  </p>
                </div>
              </div>

              {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_TELEHEALTH_LINK && appointment.conferenceLink && (
                <div className='mt-1 ml-6'>
                  <a
                    href={appointment.conferenceLink}
                    className='text-[#0000EE]'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {appointment.conferenceLink}
                  </a>

                  <div className='w-full flex justify-between gap-5'>
                    <Button
                      label='Copy to clipboard'
                      icon='pi pi-link'
                      iconPos='right'
                      size='small'
                      outlined
                      className={styles.duplicateButton}
                      onClick={() =>
                        navigator.clipboard.writeText(appointment.conferenceLink ?? '')
                      }
                    />

                    <Button
                      label='Delete zoom link'
                      icon='pi pi-times'
                      iconPos='right'
                      size='small'
                      outlined
                      className={styles.deleteButton}
                      onClick={() => setShowConfirmAppointmentDelete(true)}
                      disabled={!hasPermission(Permission.EDIT_APPOINTMENT)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.row}>
              <i className='pi pi-comment' />
              <p>
                <b>Description:</b> - {appointment?.description}
              </p>
            </div>
            <div className={styles.row}>
              <i className='pi pi-user' />
              <p>
                <b>Patient:</b>{' '}
                {appointment.firstName
                  ? `${appointment.firstName}${appointment.firstNameUsed ? ` "${appointment.firstNameUsed}" ` : ' '}${appointment.lastName ? appointment.lastName : ''}`
                  : '-'}{' '}
                {appointment.patientId ? `${appointment.patientId} ` : ''}
                {appointment.patientId && (
                  <a
                    className='text-blue-600'
                    target='_blank'
                    href={getPatientAthenaChartUrl(appointment.patientId)}
                    rel='noreferrer'
                  >
                    (Athena Chart)
                  </a>
                )}
              </p>
            </div>
            <div className={styles.row}>
              <i className='pi pi-id-card' />
              <p>
                <b>Provider:</b> {appointment.provider ? appointment.provider.label : '-'}
              </p>
            </div>
            <div className={styles.row}>
              <i className='pi pi-flag' />
              <p>
                <b>Liaison:</b>{' '}
                {appointment?.providers
                  ?.filter((a) => a.type === 'LIAISON')
                  .map((a) => a.name)
                  .join(', ') || '-'}
              </p>
            </div>
            <div className={styles.row}>
              <i className='pi pi-users' />
              <p>
                <b>Additional Clinician:</b>{' '}
                {appointment?.providers
                  ?.filter((a) => a.type === 'ADDITIONAL')
                  .map((a) => a.name)
                  .join(', ') || '-'}
              </p>
            </div>
            <div className={styles.row}>
              <i className='pi pi-wrench' />
              <p>
                <b>Resources:</b>{' '}
                {appointment?.providers
                  ?.filter((a) => a.type === 'EQUIPMENT')
                  .map((a) => a.name)
                  .join(', ') || '-'}
              </p>
            </div>
            <div className={styles.row}>
              <i className='pi pi-building' />
              <p>
                <b>Rooms:</b>{' '}
                {appointment?.rooms?.length
                  ? appointment.rooms.map((r) => r.label).join(', ')
                  : '-'}
              </p>
            </div>
            {appointment.athenaDepartmentId && (
              <div className={styles.row}>
                <i className='pi pi-tag' />
                <p>
                  <b>Athena department:</b>{' '}
                  {getAthenaDepartmentNameById(appointment.athenaDepartmentId) ?? '-'}
                </p>
              </div>
            )}
            <div className={styles.row}>
              <i className='pi pi-tag' />
              <p>
                <b>Appointment type:</b> {appointment.type ? appointment.type : '-'}
              </p>
            </div>
            <div className={styles.row}>
              <i className={classNames('pi', appointment.confirmed ? 'pi-check' : 'pi-times')} />
              <p>
                <b>{appointment.confirmed ? 'Confirmed' : 'Not confirmed'}</b>{' '}
                {appointment.athenaAppointmentId && '- Athena appointment created'}
              </p>
            </div>
          </main>

          <footer className={styles.footer}>
            <Button
              label='Edit'
              icon='pi pi-pencil'
              iconPos='right'
              size='small'
              className='bg-moss border-moss text-white rounded-[10px]'
              onClick={() => onEditAppointment(appointment)}
              disabled={!hasPermission(Permission.EDIT_APPOINTMENT)}
            />
            {hasPermission(Permission.DUPLICATE_APPOINTMENT) && (
              <Button
                label='Duplicate'
                icon='pi pi-copy'
                iconPos='right'
                size='small'
                outlined
                className='mt-2 bg-white border-moss text-moss rounded-[10px]'
                onClick={() => onDuplicateAppointment(appointment)}
                disabled={
                  !appointment.atriaAppointment || !hasPermission(Permission.CREATE_APPOINTMENT)
                }
              />
            )}

            <Button
              label='Delete'
              icon='pi pi-trash'
              iconPos='right'
              size='small'
              outlined
              className='mt-2 bg-white border-rust text-rust rounded-[10px]'
              onClick={() => onDeleteAppointment(appointment)}
              disabled={
                !appointment.atriaAppointment || !hasPermission(Permission.DELETE_APPOINTMENT)
              }
            />

            {appointment.updatedBy &&
              (env.APP_FEATURE_FLAGS.IS_TO_SHOW_APPOINTMENTS_LOG_HISTORY ? (
                <div
                  className='mt-4 border-[1px] border-solid border-[#b2b2b2] text-neutral-500 min-h-[37px] rounded-[10px] bg-[#6d6d6d17] px-[14px] py-[7px] text-sm text-center cursor-pointer'
                  onClick={() => showAppointmentAuditsHistoryDrawer()}
                >
                  <span className='font-bold'>{updatedByLabel}</span>
                  <span className='ml-1 underline'>See history</span>
                </div>
              ) : (
                <div className='mt-4'>
                  <span className='text-sm font-bold text-neutral-500'>{updatedByLabel}</span>
                </div>
              ))}
          </footer>
        </>
      )}

      <ConfirmDeleteDialog
        headerText={'Delete zoom link'}
        messageText={'Are you sure you want to delete the zoom link?'}
        visible={showConfirmAppointmentDelete}
        onCancel={() => setShowConfirmAppointmentDelete(false)}
        onHide={() => setShowConfirmAppointmentDelete(false)}
        onConfirm={deleteZoomLink}
      />
    </Sidebar>
  );
};
