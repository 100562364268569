import { NotAuthorizedHeader } from '@/components';

export const NotAuthorized = () => {
  return (
    <>
      <NotAuthorizedHeader />
      <div className='flex flex-col main-container items-center'>
        <p className='text-justify mt-40 px-20 md:px-40 lg:px-60 xl:px-80 2xl:px-96 text-lg'>
          If you are seeing this page it is because you are accessing an older version of this page
          or you are not authorized to access. Please clear your browser&apos;s cache for the last 7
          days, and if you are continuing to see this message contact Teresa Rufin. <br />
        </p>
        <a className='mt-10 underline' href='/'>
          Tap here to try logging in again.
        </a>
      </div>
    </>
  );
};
