import { SyntheticEvent, useCallback, useRef } from 'react';
import { Button } from 'primereact/button';
import { NavigateAction } from 'react-big-calendar';
import { OverlayPanel } from 'primereact/overlaypanel';

import { CalendarPicker, CreateAppointmentFromTemplate } from '..';
import { DateTime } from 'luxon';
import { Room, VisitTypeTemplates } from '@/@types';
import { Permission, useAuthContext } from '@/contexts/authContext';
import { DateTimeHelper } from '@/helpers';
import { env } from '@/utils/constants';
import MobileDetect from 'mobile-detect';

type CalendarToolbarProps = {
  date: Date;
  dailyNotes?: string;
  roomsOptionsList: Room[];
  suiteRooms: number[];
  visitTypeTemplates: VisitTypeTemplates[];
  onNavigate: (navigate: NavigateAction, date?: Date) => void;
  onDailyNotesClick?: VoidFunction;
  onNewAppointmentClick: VoidFunction;
  onAppointmentCreateByTemplateSubmit: VoidFunction;
  onCalendarViewSettingsClick: VoidFunction;
  onAppointmentsDeletedHistoryClick: VoidFunction;
  outOfOfficeProviders: string;
};

export const CalendarToolbar = ({
  date,
  dailyNotes,
  roomsOptionsList,
  suiteRooms,
  visitTypeTemplates,
  onNavigate,
  onNewAppointmentClick = () => {},
  onAppointmentCreateByTemplateSubmit = () => {},
  onDailyNotesClick = () => {},
  onCalendarViewSettingsClick = () => {},
  onAppointmentsDeletedHistoryClick = () => {},
  outOfOfficeProviders,
}: CalendarToolbarProps) => {
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  const isMobile = mobileDetect.mobile();
  const { hasPermission } = useAuthContext();
  const appointmentCreationOverlayPanelRef = useRef<OverlayPanel>(null);

  const onAppointmentFromTemplateClick = useCallback((e: SyntheticEvent) => {
    if (appointmentCreationOverlayPanelRef.current) {
      appointmentCreationOverlayPanelRef.current.toggle(e);
    }
  }, []);

  const handleAppointmentsCreationByTemplateSubmit = useCallback(() => {
    appointmentCreationOverlayPanelRef.current?.hide();
    onAppointmentCreateByTemplateSubmit();
  }, [onAppointmentCreateByTemplateSubmit]);

  return (
    <div className={`w-full ${isMobile ? 'landscape:hidden' : ''}`}>
      <div className='w-full grid grid-cols-1 lg:grid-cols-6 mb-4 max-lg:gap-2'>
        <div className='flex lg:justify-end justify-end items-center col-span-2'>
          <Button
            className='mr-2 hidden lg:block'
            label='Today'
            size='small'
            onClick={() => {
              const today = DateTime.now().toJSDate();
              onNavigate('DATE', today);
            }}
          />
        </div>
        <div className='flex items-center justify-center col-span-2'>
          <div className='flex w-11/12 justify-center items-center'>
            <Button
              icon='pi pi-chevron-left'
              aria-label='Previous day'
              rounded
              outlined
              className='w-[40px] lg:w-[auto] lg:min-w-[48px] h-[40px] lg:h-[auto] lg:min-h-[48px]'
              onClick={() => onNavigate('PREV')}
            />
            <CalendarPicker
              date={date}
              onChange={(newDate: Date) => {
                const dateToUse = new Date(
                  DateTimeHelper.returnOnlyDateYYYYmmDD(newDate) + 'T04:04:00-04:00'
                );

                onNavigate('DATE', dateToUse);
              }}
            />
            <Button
              icon='pi pi-chevron-right'
              aria-label='Next day'
              rounded
              outlined
              className='w-[40px] lg:w-[auto] lg:min-w-[48px] h-[40px] lg:h-[auto] lg:min-h-[48px]'
              onClick={() => onNavigate('NEXT')}
            />
          </div>
        </div>
        <div className='flex items-center justify-center col-span-1'>
          <Button
            icon='pi pi-comment'
            rounded
            text
            aria-label='Daily notes'
            tooltip='Daily notes'
            tooltipOptions={{ position: 'bottom' }}
            onClick={onDailyNotesClick}
          />
          <Button
            icon='pi pi-cog'
            rounded
            text
            aria-label='Toggles'
            tooltip='Calendar settings'
            tooltipOptions={{ position: 'bottom' }}
            onClick={onCalendarViewSettingsClick}
          />

          {env.APP_FEATURE_FLAGS.IS_TO_SHOW_APPOINTMENTS_DELETED_HISTORY && (
            <Button
              icon='pi pi-trash'
              rounded
              text
              aria-label='Toggles'
              tooltip='Deleted appointments history'
              tooltipOptions={{ position: 'bottom' }}
              onClick={onAppointmentsDeletedHistoryClick}
            />
          )}
        </div>
        <div className='flex items-center lg:justify-end justify-center col-span-1'>
          <div className='flex items-center h-full'>
            {hasPermission(Permission['CREATE_APPOINTMENT:TEMPLATE']) ? (
              <>
                <Button
                  label='New Appointment'
                  icon='pi pi-plus'
                  size='small'
                  className='rounded-s-[10px] rounded-e-none p-[var(--space-xs)] h-full text-xs'
                  onClick={onNewAppointmentClick}
                  disabled={!hasPermission(Permission.CREATE_APPOINTMENT)}
                />

                <Button
                  icon='pi pi-chevron-down'
                  size='small'
                  className='rounded-s-none rounded-e-[10px] border-l-0 p-[var(--space-xs)] h-full'
                  onClick={onAppointmentFromTemplateClick}
                  disabled={!hasPermission(Permission.CREATE_APPOINTMENT)}
                />
                <OverlayPanel
                  ref={appointmentCreationOverlayPanelRef}
                  className='create-new-appointment-overlay'
                >
                  <CreateAppointmentFromTemplate
                    roomsOptionsList={roomsOptionsList}
                    suiteRooms={suiteRooms}
                    handleCreateNew={onNewAppointmentClick}
                    onSave={handleAppointmentsCreationByTemplateSubmit}
                    currentDate={DateTime.fromJSDate(date).toFormat('MM/dd/yyyy')}
                    visitTypeTemplates={visitTypeTemplates}
                  />
                </OverlayPanel>
              </>
            ) : (
              <Button
                label='New Appointment'
                icon='pi pi-plus'
                size='small'
                className='rounded-s-[10px] p-[var(--space-xs)] h-full text-xs'
                onClick={onNewAppointmentClick}
                disabled={!hasPermission(Permission.CREATE_APPOINTMENT)}
              />
            )}
          </div>
        </div>
      </div>

      {dailyNotes && (
        <div className='w-full bg-gray-100 py-2 px-3 mt-4 flex items-center rounded-[10px] mb-4'>
          <div className='flex-1 flex flex-col items-start text-xs lg:text-sm'>
            <span className='font-bold block text-off-black'>Daily notes</span>

            {dailyNotes && (
              <p className='text-off-black' dangerouslySetInnerHTML={{ __html: dailyNotes }} />
            )}
          </div>
          <Button
            icon='pi pi-pencil'
            size='small'
            text
            rounded
            onClick={onDailyNotesClick}
            className='w-[50px]'
            tooltip='Edit daily notes'
            tooltipOptions={{ position: 'bottom' }}
          />
        </div>
      )}

      {outOfOfficeProviders && (
        <div className='w-full min-h-8 px-5 py-2 bg-neutral-100 border-l border-r border-t border-gray-200 justify-center items-center gap-3 inline-flex'>
          <div className="text-center text-sm text-neutral-700 text-[11px] font-bold font-['Noto Sans'] leading-none">
            OOO: {outOfOfficeProviders}
          </div>
        </div>
      )}
    </div>
  );
};
