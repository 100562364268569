import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';

import { useEffect, useMemo, useState } from 'react';
import { appointmentsService } from '@/services';
import { AppointmentAuditActionToDialogTitle, Atria } from '@/@types';
import { SchedulerAppointmentHistoryHelper } from '@/helpers';
import { Skeleton } from 'primereact/skeleton';
import { useToastContext } from '@/contexts';

type AppointmentAuditsHistoryDrawerProps = {
  visible: boolean;
  onHide: () => void;
  onReturn: () => void;
  appointmentId?: number;
};

const skeletonAnimation = 'wave';

export const AppointmentAuditsHistoryDrawer = ({
  visible,
  onHide,
  onReturn,
  appointmentId,
}: AppointmentAuditsHistoryDrawerProps) => {
  const { toast } = useToastContext();
  const [appointmentHistory, setAppointmentHistory] =
    useState<Atria.FindAppointmentHistory.Response>([]);
  const [skeletonHidden, setSkeletonHidden] = useState(true);

  const formattedAppointmentHistory = useMemo(
    () => SchedulerAppointmentHistoryHelper.getFormattedAppointmentAudits(appointmentHistory),
    [appointmentHistory]
  );

  const handleGetAppointmentHistory = async (selectedAppointmentId: number) => {
    try {
      const { data } = await appointmentsService.findAppointmentHistory(selectedAppointmentId);

      setAppointmentHistory(data);
    } catch {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Unable to load history',
        life: 3000,
      });
    } finally {
      setSkeletonHidden(false);
    }
  };

  useEffect(() => {
    if (!appointmentId) return;

    handleGetAppointmentHistory(appointmentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  return (
    <Sidebar
      visible={visible}
      onHide={onHide}
      position='right'
      showCloseIcon={false}
      className='w-[500px]'
      blockScroll={true}
    >
      <>
        <header className='flex flex-col justify-between mb-10 mt-[21px]'>
          <div className='flex justify-between'>
            <h1 className='text-[#3C4E3D] text-[32px] h-[68px] flex items-center leading-10 tracking-[-0.96px] font-medium'>
              History
            </h1>
            <Button
              className='color-[#3C4E3D] text-xl'
              icon='pi pi-times'
              rounded
              text
              aria-label='close'
              onClick={onHide}
            />
          </div>
          <div>
            <Button label='Return to details' text icon='pi pi-arrow-left' onClick={onReturn} />
          </div>
        </header>

        <main className='overflow-y-auto max-w-[calc(100vh-150px)]'>
          {!skeletonHidden ? (
            formattedAppointmentHistory.map((appointmentAudit) => (
              <div
                className='w-full min-h-[124px] p-5 bg-stone-100 rounded-xl flex-col justify-start items-start gap-1 inline-flex bg-[#F4F1EE] mb-4'
                key={appointmentAudit.id}
              >
                <div className="text-[#3C4E3D] text-lg font-bold font-['Scto Grotesk A'] leading-7">
                  {AppointmentAuditActionToDialogTitle[appointmentAudit.action] ?? ''}
                </div>
                <div className="text-[#757575] text-base font-medium font-['Scto Grotesk A'] leading-normal">
                  {appointmentAudit.formattedDate}
                </div>
                <div className="text-[#757575] text-base font-medium font-['Scto Grotesk A'] leading-normal">
                  User: {appointmentAudit.authorEmail}
                </div>
                {!!appointmentAudit.changesMade && (
                  <div className="text-[#757575] text-base font-medium font-['Scto Grotesk A'] leading-normal mt-8">
                    Changes made: {appointmentAudit.changesMade}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className='flex flex-col space-y-4 overflow-y-auto flex-1'>
              <Skeleton className='flex w-full h-[124px]' animation={skeletonAnimation}></Skeleton>
              <Skeleton className='flex w-full h-[124px]' animation={skeletonAnimation}></Skeleton>
              <Skeleton className='flex w-full h-[124px]' animation={skeletonAnimation}></Skeleton>
              <Skeleton className='flex w-full h-[124px]' animation={skeletonAnimation}></Skeleton>
              <Skeleton className='flex w-full h-[124px]' animation={skeletonAnimation}></Skeleton>
            </div>
          )}
        </main>
      </>
    </Sidebar>
  );
};
