import React, { createFactory } from 'react';
import PropTypes from 'prop-types';
import CustomEventContainerWrapper from './CustomEventContainerWrapper';
import { Calendar, CalendarProps, Components } from 'react-big-calendar';
import EventWrapper from '../dragAndDrop/EventWrapper';
import WeekWrapper, { WeekWrapperProps } from '../dragAndDrop/WeekWrapper';
import DnDContext, { DragAndDropState } from '../dragAndDrop/DnDContext';
import { AppointmentWithDate } from '@/@types';

type ComponentsExtended = Components & {
  weekWrapper?: React.ComponentType<WeekWrapperProps>;
};

const mergeComponents = (
  previousComponents: ComponentsExtended | undefined = {},
  newComponents: ComponentsExtended
): ComponentsExtended => {
  const keys = Object.keys(newComponents);
  const newComponentsMerged: ComponentsExtended = keys.reduce((acc, key) => {
    const previousComponent = previousComponents[key as keyof ComponentsExtended];
    const newComponent = newComponents[key as keyof ComponentsExtended];
    if (!previousComponent) {
      return {
        ...acc,
        [key]: newComponent,
      };
    }

    const factory1 = createFactory(previousComponent as any);
    const factory2 = createFactory(newComponent as any);

    const Nest: React.ComponentType<any> = ({ children, ...props }) => {
      const child = factory2(props, children);
      return factory1(props, child);
    };

    return {
      ...acc,
      [key]: Nest,
    };
  }, {});
  return {
    ...previousComponents,
    ...newComponentsMerged,
  };
};

interface CustomCalendarProps extends CalendarProps {
  onEventDrop?: (interactionInfo: any) => void;
  onEventResize?: (interactionInfo: any) => void;
  onDragStart?: (interactionInfo: any) => void;
  onDragOver?: (event: React.DragEvent) => void;
  onDropFromOutside?: (event: object) => void;
  dragFromOutsideItem?: () => object;
  draggableAccessor?: any;
  resizableAccessor?: any;
  selectable?: true | false | 'ignoreEvents';
  resizable?: boolean;
  uniqueSelectedEvents: AppointmentWithDate[];
  shouldDragStart: boolean;
  shouldSelectStart: boolean;
}
export default class CustomWithDragAndDrop extends React.Component {
  static propTypes = {
    shouldDragStart: PropTypes.bool,
    shouldSelectStart: PropTypes.bool,
    elementProps: PropTypes.object,
    onEventDrop: PropTypes.func,
    onEventResize: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragOver: PropTypes.func,
    onDropFromOutside: PropTypes.func,

    dragFromOutsideItem: PropTypes.func,

    components: PropTypes.shape({
      event: PropTypes.elementType,
      eventWrapper: PropTypes.elementType,
      eventContainerWrapper: PropTypes.elementType,
      dateCellWrapper: PropTypes.elementType,
      dayColumnWrapper: PropTypes.elementType,
      timeSlotWrapper: PropTypes.elementType,
      timeGutterHeader: PropTypes.elementType,
      timeGutterWrapper: PropTypes.elementType,
      resourceHeader: PropTypes.elementType,

      toolbar: PropTypes.elementType,

      agenda: PropTypes.shape({
        date: PropTypes.elementType,
        time: PropTypes.elementType,
        event: PropTypes.elementType,
      }),

      day: PropTypes.shape({
        header: PropTypes.elementType,
        event: PropTypes.elementType,
      }),
      week: PropTypes.shape({
        header: PropTypes.elementType,
        event: PropTypes.elementType,
      }),
      month: PropTypes.shape({
        header: PropTypes.elementType,
        dateHeader: PropTypes.elementType,
        event: PropTypes.elementType,
      }),
    }),
    draggableAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    resizableAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

    selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),
    resizable: PropTypes.bool,

    events: PropTypes.array,
    uniqueSelectedEvents: PropTypes.array,

    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
  };

  components: Components = {};

  state: DragAndDropState = {
    interacting: false,
    action: null,
  };

  constructor(public props: CustomCalendarProps) {
    super(props);
  }

  getDnDContextValue() {
    return {
      draggable: {
        onStart: this.handleInteractionStart,
        onEnd: this.handleInteractionEnd,
        onBeginAction: this.handleBeginAction,
        onDropFromOutside: this.props.onDropFromOutside,
        dragFromOutsideItem: this.props.dragFromOutsideItem,
        draggableAccessor: this.props.draggableAccessor,
        resizableAccessor: this.props.resizableAccessor,
        dragAndDropAction: this.state,
        events: this.props.events as AppointmentWithDate[],
        selectedEvents: this.props.uniqueSelectedEvents,
        min: this.props.min,
        max: this.props.max,
      },
    };
  }

  defaultOnDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  handleBeginAction = (event: object, action: string, direction?: string) => {
    if (!this.props.shouldDragStart) return;

    this.setState({ event, action, direction });
    const { onDragStart } = this.props;
    if (onDragStart) onDragStart({ event, action, direction });
  };

  handleInteractionStart = () => {
    if (this.state.interacting === false) {
      this.setState({ interacting: true });
    }
  };

  // todo: type interactionInfo
  handleInteractionEnd = (interactionInfo: any) => {
    const { action, event } = this.state;
    if (!action) {
      return;
    }

    this.setState({
      action: null,
      event: null,
      interacting: false,
      direction: null,
    });

    if (interactionInfo == null) {
      return;
    }

    interactionInfo.event = event;
    const { onEventDrop, onEventResize } = this.props;
    if (action === 'move' && onEventDrop) {
      onEventDrop(interactionInfo);
    }
    if (action === 'resize' && onEventResize) {
      onEventResize(interactionInfo);
    }
  };

  render() {
    const { components, ...props } = this.props;
    const { interacting } = this.state;

    delete props.onEventDrop;
    delete props.onEventResize;

    props.selectable = props.selectable && props.shouldSelectStart ? 'ignoreEvents' : false;

    this.components = mergeComponents(components as ComponentsExtended, {
      eventWrapper: EventWrapper,
      weekWrapper: WeekWrapper,
      eventContainerWrapper: CustomEventContainerWrapper as unknown as React.ComponentType,
    });

    props.className = `${props.className || ''} rbc-addons-dnd ${
      !!interacting && 'rbc-addons-dnd-is-dragging'
    }`;

    const context = this.getDnDContextValue();

    return (
      <DnDContext.Provider value={context}>
        <Calendar {...props} components={this.components} />
      </DnDContext.Provider>
    );
  }
}
