import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '@/routes/routes';
import { authService } from './services/authService';
import { useAuthContext } from './contexts/authContext';
import { AxiosResponse } from 'axios';
import { env } from '@/utils/constants';
import { CheckNetworkConnectivity } from './components';

export function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useAuthContext();

  if (location.pathname !== '/login/callback') {
    location.search?.startsWith('?filters')
      ? localStorage.setItem('filters', location.search)
      : localStorage.removeItem('filters');
  }

  const oktaAuth = new OktaAuth({
    issuer: env.APP_OKTA_DOMAIN,
    clientId: env.APP_OKTA_CLIENT_ID,
    redirectUri: `${env.APP_FRONT_URL}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  });

  async function restoreOriginalUri(_oktaAuth: OktaAuth, originalUri: any) {
    const token = _oktaAuth.getAccessToken();
    if (!token) {
      return;
    }
    const urlToGo = toRelativeUrl(originalUri || '/', window.location.origin);
    authService
      .createAccessTokenByOktaAccessToken(token)
      .then((response: AxiosResponse) => {
        setUser((user) => ({ ...user, permissions: response.data.permissions ?? [] }));
        navigate(urlToGo);
      })
      .catch(() => {
        _oktaAuth.clearStorage();
        setTimeout(() => {
          navigate(urlToGo);
        }, 10000);
      });
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <CheckNetworkConnectivity />
      <AppRoutes />
    </Security>
  );
}
