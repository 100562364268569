import { AppointmentWithDate, PatientVisit, isPatientVisit } from '@/@types';
import { Permission, useAuthContext } from '@/contexts';
import { DateTime, Interval } from 'luxon';
import { useCallback, useMemo } from 'react';

type Props = {
  selectedEvents: AppointmentWithDate[];
  appointments: AppointmentWithDate[];
  onEventSelect: React.Dispatch<React.SetStateAction<AppointmentWithDate[]>>;
};

export function useSchedulerSelecting({ selectedEvents, appointments, onEventSelect }: Props) {
  const { hasPermission } = useAuthContext();

  const uniqueSelectedEvents = useMemo(
    () =>
      selectedEvents.reduce((all, current) => {
        const item = all.find((a) => a.id === current.id);
        if (!item) {
          all.push(current);
        }
        return all;
      }, [] as AppointmentWithDate[]),
    [selectedEvents]
  );

  const handleSelectIndividualEvents = useCallback(
    (appointment: AppointmentWithDate) => {
      if (!hasPermission(Permission.CREATE_APPOINTMENT) || isPatientVisit(appointment)) return;
      onEventSelect((prev) => {
        const currentList = [...prev];
        const selectedIndex = currentList.findIndex(
          (appt) => appt.id === appointment.id && appt.resourceId === appointment.resourceId
        );
        if (selectedIndex !== -1) {
          return currentList.filter((_, index) => index !== selectedIndex);
        }
        const list = [...currentList, appointment];
        return list;
      });
    },
    [hasPermission, onEventSelect]
  );

  const handleSelectMultipleEvents = useCallback(
    async (appointment: AppointmentWithDate) => {
      if (!hasPermission(Permission.CREATE_APPOINTMENT) || isPatientVisit(appointment)) return;
      onEventSelect((prev) => {
        if (prev.length === 0) return [appointment];
        const currentList = [...prev];
        const previousAppointmentSelected = currentList[currentList.length - 1];
        let interval = Interval.fromDateTimes(previousAppointmentSelected.date, appointment.end);
        if (appointment.date < previousAppointmentSelected.date) {
          interval = Interval.fromDateTimes(appointment.date, previousAppointmentSelected.end);
        }
        const allFromSameColumn = appointments.filter((appt) => {
          const sameColumn = appt?.resourceId === appointment.resourceId;
          const samePatientId =
            previousAppointmentSelected.patientId === appointment.patientId &&
            appointment.patientId === appt.patientId;
          const isInInterval = interval.contains(DateTime.fromJSDate(appt.date));
          return sameColumn && samePatientId && isInInterval;
        });
        return [...currentList, ...allFromSameColumn, appointment];
      });
    },
    [appointments, hasPermission, onEventSelect]
  );

  const handleOnClearSelectedEvents = useCallback(() => {
    onEventSelect([]);
  }, [onEventSelect]);

  const handleOnSelectBackgroundEvent = useCallback(
    (event: PatientVisit) => {
      if (!hasPermission(Permission.CREATE_APPOINTMENT)) return;

      if (event.appointments && event.appointments.length > 0) {
        onEventSelect((prev) => {
          if (prev.length === 0) return [...event.appointments];

          return [...prev, ...event.appointments];
        });
      }
    },
    [hasPermission, onEventSelect]
  );

  return {
    uniqueSelectedEvents,
    handleSelectIndividualEvents,
    handleSelectMultipleEvents,
    handleOnClearSelectedEvents,
    handleOnSelectBackgroundEvent,
  };
}
