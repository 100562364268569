import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { ProviderRoom, Room, RoomType } from '@/@types';
import { roomsService } from '@/services';

type Type = {
  rooms: Room[];
  roomListAsResources: ProviderRoom[];
  suiteRoomsIds: number[];
  findAllRooms: () => Promise<void>;
};

const Context = createContext<Type>({
  rooms: [],
  roomListAsResources: [],
  suiteRoomsIds: [],
  findAllRooms: async () => {},
});

type Props = {
  children?: React.ReactNode;
};

export function RoomsContext({ children }: Props) {
  const [rooms, setRooms] = useState<Room[]>([]);

  const roomListAsResources: ProviderRoom[] = useMemo(() => {
    return rooms.map(({ name, location, id, defaultView, displayOrder, type }) => ({
      resourceId: `room-${id}`,
      resourceTitle: name,
      prefix: 'room',
      locationId: location.id,
      defaultView: defaultView,
      displayOrder: displayOrder,
      type: RoomType[type as keyof typeof RoomType],
    }));
  }, [rooms]);

  const suiteRoomsIds = useMemo(() => {
    return rooms.filter((r) => r.isSuite).map((r) => r.id);
  }, [rooms]);

  const findAllRooms = useCallback(async () => {
    const { data } = await roomsService.findAll();
    setRooms(data);
  }, []);

  return (
    <Context.Provider
      value={{
        rooms,
        roomListAsResources,
        suiteRoomsIds,
        findAllRooms,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useRooms = () => useContext(Context);
