import classNames from 'classnames';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NavigateAction, Views, luxonLocalizer } from 'react-big-calendar';
import {
  AppointmentEventType,
  AppointmentWithDate,
  GoogleEventProvider,
  Origin,
  PatientVisit,
  Resources,
  Room,
  VisitTypeTemplates,
  googleWorkingTypeToLocationMap,
  isPatientVisit,
} from '@/@types';
import { useSchedulerSelecting } from '@/hooks';

import { CalendarEventsControl, CalendarBackgroundEvent, CalendarEvent, CalendarToolbar } from '..';
import './Calendar.css';
import CustomWithDragAndDrop from './react-big-calendar/lib/addons/customWithDragAndDrop/CustomWithDragAndDrop';
import { Permission, useAuthContext } from '@/contexts/authContext';
import { env, SchedulerConstants } from '@/utils/constants';
import MobileDetect from 'mobile-detect';

const localizer = luxonLocalizer(DateTime);

type CustomEventProps = {
  event: AppointmentWithDate | PatientVisit;
  title: string;
  isVisitEvent?: boolean;
};

type CustomToolbarProps = {
  date: Date;
  onNavigate: (navigate: NavigateAction, date?: Date) => void;
};

type SelectSlotProps = {
  start: Date;
  end: Date;
  resourceId: string;
  slots: Array<Date>;
  action: 'select' | 'click' | 'doubleClick';
  bounds: {
    top: number;
    left: number;
    x: number;
    y: number;
    right: number;
    bottom: number;
  };
};

export type DragStartEvent = {
  action: string;
  direction?: string;
  event: AppointmentWithDate | PatientVisit;
};

export type ChangeEvent = {
  resourceId: string;
  end: Date;
  start: Date;
  event: AppointmentWithDate | PatientVisit;
  events: AppointmentWithDate[] | null;
};

const APPOINTMENT_TYPES_CLASSES = {
  performanceMovement: [283, 364, 282, 369, 284, 365, 221, 366, 367, 303, 368],
  cardiology: [142, 143, 144, 5, 6, 321],
  nutrition: [561, 562, 641, 642],
  neuro: [188, 185, 464, 201, 202, 201, 461, 462, 186, 463, 187, 182, 341, 181, 184, 861],
  endocrinology: [581, 601, 602, 603],
  gynecology: [21, 22, 23, 741, 742, 841, 842, 843, 844, 845, 846, 847, 848],
  mri: [122],
  ultrasound: [127],
  ct: [121],
  dexa: [123],
  xray: [125],
  mammo: [124],
  integratMed: [721, 722, 723, 724, 725, 726, 727, 728],
  memberLunch: [130],
  steamCellProgram: [501],
};

type CalendarProps = {
  appointments: (AppointmentWithDate & AppointmentEventType)[];
  eventsOOOAndAllDay: GoogleEventProvider[];
  patientVisits: PatientVisit[];
  dailyNotes: string;
  resources: Resources[];
  selectedEvents: AppointmentWithDate[];
  currentDate: Date;
  roomsOptionsList: Room[];
  suiteRooms: number[];
  hidePatientName?: boolean;
  showAthenaAppointments?: boolean;
  showGoogleCalendarEvents?: boolean;
  showFullCalendar?: boolean;
  showPatientBackgroundEvents?: boolean;
  showUnconfirmedAppointments?: boolean;
  onDailyNotesClick: VoidFunction;
  onCurrentDateChange: (date: Date) => void;
  onSelectEvent: (appointment: AppointmentWithDate | PatientVisit, e: React.MouseEvent) => void;
  onDragStart: (event: DragStartEvent) => void;
  onSelectSlot: (slotProps: SelectSlotProps) => void;
  onHidePatientName: (checked: boolean) => void;
  onShowFullCalendar: (checked: boolean) => void;
  onShowGoogleCalendarEvents: (checked: boolean) => void;
  onShowAthenaAppointments: (checked: boolean) => void;
  onEventDrop: (event: ChangeEvent) => void;
  onEventResize: (event: ChangeEvent) => void;
  handleResizableAccessor: (event: AppointmentWithDate) => boolean;
  handleDraggableAccessor: (event: AppointmentWithDate) => boolean;
  onEventSelect: React.Dispatch<React.SetStateAction<AppointmentWithDate[]>>;
  onEditByControlPanel: VoidFunction;
  onSelectAllSameColumnByControlPanel: VoidFunction;
  onDuplicateByControlPanel: VoidFunction;
  onDelete: VoidFunction;
  onNewAppointmentClick: VoidFunction;
  onCalendarViewSettingsClick: VoidFunction;
  onAppointmentCreateByTemplateSubmit: VoidFunction;
  visitTypeTemplates: VisitTypeTemplates[];
  onAppointmentsDeletedHistoryClick: VoidFunction;
  location: number;
};

export const Calendar = ({
  appointments,
  patientVisits,
  dailyNotes,
  resources,
  selectedEvents,
  currentDate,
  roomsOptionsList,
  suiteRooms,
  showFullCalendar = false,
  hidePatientName = false,
  showPatientBackgroundEvents,
  showUnconfirmedAppointments = true,
  onDailyNotesClick,
  onCurrentDateChange,
  onSelectEvent,
  onSelectSlot,
  onDragStart,
  onEventDrop,
  onEventResize,
  handleResizableAccessor,
  handleDraggableAccessor,
  onEventSelect,
  onEditByControlPanel,
  onSelectAllSameColumnByControlPanel,
  onDuplicateByControlPanel,
  onDelete,
  onNewAppointmentClick,
  onCalendarViewSettingsClick,
  onAppointmentCreateByTemplateSubmit,
  visitTypeTemplates,
  onAppointmentsDeletedHistoryClick,
  location,
  eventsOOOAndAllDay,
}: CalendarProps) => {
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  const isMobile = mobileDetect.mobile();
  const { hasPermission } = useAuthContext();
  const [calendarTimeRange, setCalendarTimeRange] = useState({
    start: new Date(),
    end: new Date(),
  });

  const appointmentsWithoutAllDayEvents = useMemo(
    () => appointments.filter((event) => !event.isAllDay),
    [appointments]
  );

  const { uniqueSelectedEvents, handleOnClearSelectedEvents, handleOnSelectBackgroundEvent } =
    useSchedulerSelecting({
      appointments: appointmentsWithoutAllDayEvents,
      selectedEvents,
      onEventSelect,
    });

  const showBackground = useMemo(() => {
    if (!showPatientBackgroundEvents) {
      return false;
    }
    if (
      !showUnconfirmedAppointments &&
      !appointmentsWithoutAllDayEvents.some((a) => a.confirmed && a.origin === Origin.Atria)
    ) {
      return false;
    }
    return true;
  }, [appointmentsWithoutAllDayEvents, showUnconfirmedAppointments, showPatientBackgroundEvents]);

  const eventStyleGetter = useCallback(
    (event: AppointmentWithDate | PatientVisit) => {
      if (isPatientVisit(event)) {
        return { className: 'event-wrapper rbc-background-event bg-zinc' };
      }
      const isSelected = selectedEvents.find(
        ({ id, resourceId }) => id === event.id && resourceId === event.resourceId
      );
      return {
        className: classNames('event-wrapper', {
          'bg-gray-200': isSelected,
          'text-[var(--kelp)]': isSelected,
          conflictEvent:
            env.APP_FEATURE_FLAGS.IS_TO_SHOW_APPOINTMENTS_CONFLICTS && event.hasConflict,
          external: event.externalClient,
          fullWidthEvent: showPatientBackgroundEvents && event.hasPatientVisit,
          ...([Origin.Atria].includes(event.origin) && {
            appointmentConfirmed: event.confirmed,
            appointmentNotConfirmed: !event.confirmed,
          }),
          ...(event.typeId && {
            performanceMovementEvent: APPOINTMENT_TYPES_CLASSES.performanceMovement.includes(
              event.typeId
            ),
            cardiologyEvent: APPOINTMENT_TYPES_CLASSES.cardiology.includes(event.typeId),
            nutritionEvent: APPOINTMENT_TYPES_CLASSES.nutrition.includes(event.typeId),
            neuroEvent: APPOINTMENT_TYPES_CLASSES.neuro.includes(event.typeId),
            endocrinologyEvent: APPOINTMENT_TYPES_CLASSES.endocrinology.includes(event.typeId),
            gynecologyEvent: APPOINTMENT_TYPES_CLASSES.gynecology.includes(event.typeId),
            mriEvent: APPOINTMENT_TYPES_CLASSES.mri.includes(event.typeId),
            ultrasoundEvent: APPOINTMENT_TYPES_CLASSES.ultrasound.includes(event.typeId),
            ctEvent: APPOINTMENT_TYPES_CLASSES.ct.includes(event.typeId),
            dexaEvent: APPOINTMENT_TYPES_CLASSES.dexa.includes(event.typeId),
            xrayEvent: APPOINTMENT_TYPES_CLASSES.xray.includes(event.typeId),
            mammoEvent: APPOINTMENT_TYPES_CLASSES.mammo.includes(event.typeId),
            integratMed: APPOINTMENT_TYPES_CLASSES.integratMed.includes(event.typeId),
            memberLunch: APPOINTMENT_TYPES_CLASSES.memberLunch.includes(event.typeId),
            steamCellProgram: APPOINTMENT_TYPES_CLASSES.steamCellProgram.includes(event.typeId),
          }),
          googleEvent: event.origin === Origin.Google,
          athenaEvent: event.origin === Origin.Athena,
        }),
      };
    },
    [selectedEvents, showPatientBackgroundEvents]
  );

  const outOfOfficeProviders = useMemo(() => {
    return eventsOOOAndAllDay
      .filter((appt) => appt?.locationId === location)
      .map((appt) => appt.name)
      .join(', ');
  }, [eventsOOOAndAllDay, location]);

  const renderToolbar = useCallback(
    (props: CustomToolbarProps) => (
      <CalendarToolbar
        outOfOfficeProviders={outOfOfficeProviders}
        date={props.date}
        roomsOptionsList={roomsOptionsList}
        suiteRooms={suiteRooms}
        onAppointmentCreateByTemplateSubmit={onAppointmentCreateByTemplateSubmit}
        dailyNotes={dailyNotes}
        onNavigate={props.onNavigate}
        onDailyNotesClick={onDailyNotesClick}
        onCalendarViewSettingsClick={onCalendarViewSettingsClick}
        onNewAppointmentClick={onNewAppointmentClick}
        visitTypeTemplates={visitTypeTemplates}
        onAppointmentsDeletedHistoryClick={onAppointmentsDeletedHistoryClick}
      />
    ),
    [
      dailyNotes,
      onAppointmentCreateByTemplateSubmit,
      onCalendarViewSettingsClick,
      onDailyNotesClick,
      onNewAppointmentClick,
      roomsOptionsList,
      suiteRooms,
      visitTypeTemplates,
      onAppointmentsDeletedHistoryClick,
      outOfOfficeProviders,
    ]
  );

  const renderNewEvent = useCallback(
    ({ event }: CustomEventProps) => {
      if (isPatientVisit(event)) {
        if (!showBackground) {
          return;
        }
        return (
          <CalendarBackgroundEvent
            event={event}
            onSelectBackgroundEvent={handleOnSelectBackgroundEvent}
            hidePatientName={hidePatientName}
          />
        );
      }
      return <CalendarEvent appointment={event} />;
    },
    [hidePatientName, showBackground, handleOnSelectBackgroundEvent]
  );

  const canEditAppointment = isMobile ? false : hasPermission(Permission.EDIT_APPOINTMENT);

  const providersWithAllDay = useMemo(() => {
    const allDayAppointments = appointments.filter((appt) => appt.isAllDay);
    const allDayApptOOO = allDayAppointments.filter(({ isOutOfOffice }) => isOutOfOffice);
    const allDayApptLocation = allDayAppointments.filter(
      ({ workingLocationType }) => workingLocationType
    );
    const justAllDay = allDayAppointments.filter(
      ({ workingLocationType, isOutOfOffice }) => !workingLocationType && !isOutOfOffice
    );

    return [...allDayApptLocation, ...allDayApptOOO, ...justAllDay].reduce(
      (
        providers: Map<string, (AppointmentWithDate & AppointmentEventType)[]>,
        appointment: AppointmentWithDate & AppointmentEventType
      ) => {
        const resourceId = appointment.resourceId as string;
        const hasResourceId = providers.has(resourceId);

        const appointmentsFromProvider = !hasResourceId
          ? [appointment]
          : [
              ...(providers.get(resourceId) as (AppointmentWithDate & AppointmentEventType)[]),
              appointment,
            ];

        providers.set(resourceId, appointmentsFromProvider);

        return providers;
      },
      new Map()
    );
  }, [appointments]);

  const customResourceHeader = useCallback(
    ({
      resource: { resourceId },
      label,
    }: {
      label: string;
      resource: {
        resourceId: string;
      };
    }) => {
      const isAllDay = providersWithAllDay.has(resourceId);

      if (isAllDay) {
        const events = providersWithAllDay.get(resourceId);
        const outOfOfficeEventId = events?.find((event) => event.isOutOfOffice)?.id;

        return (
          <div className='flex flex-col flex-wrap justify-center gap-1'>
            <div>{label}</div>

            <div className='flex flex-wrap justify-center items-center gap-3'>
              {events?.map((event) => (
                <>
                  <div className='flex items-center justify-center'>
                    {event?.workingLocationType &&
                      googleWorkingTypeToLocationMap[event?.workingLocationType] && (
                        <div>
                          <div className="text-white text-[10px] font-normal font-['Inter'] bg-[#869D89] rounded-[10px] px-2 py-[3px]">
                            {googleWorkingTypeToLocationMap[event?.workingLocationType]}
                          </div>
                        </div>
                      )}

                    {event.isOutOfOffice && event.id === outOfOfficeEventId && (
                      <div className='text-[#F00] font-bold text-xs'>Out of Office</div>
                    )}

                    {!event?.workingLocationType && !event.isOutOfOffice && (
                      <div className='text-[#757575] font-normal text-xs'>{event.title}</div>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        );
      }

      return (
        <div className='custom-resource-header'>
          <h1>{label}</h1>
        </div>
      );
    },
    [providersWithAllDay]
  );

  const calendarProps = useMemo(
    () =>
      ({
        className: `new-calendar h-[calc(100vh-80px)] md:h-[calc(100vh-140px)] ${isMobile ? 'landscape:h-screen' : ''}`,
        shouldDragStart: canEditAppointment,
        shouldSelectStart: hasPermission(Permission.CREATE_APPOINTMENT),
        dayLayoutAlgorithm: 'no-overlap',
        defaultDate: currentDate,
        defaultView: Views.DAY,
        startAccessor: 'date',
        endAccessor: 'end',
        events: appointmentsWithoutAllDayEvents,
        backgroundEvents: showBackground ? patientVisits : [],
        uniqueSelectedEvents: uniqueSelectedEvents,
        localizer: localizer,
        max: calendarTimeRange.end,
        min: calendarTimeRange.start,
        resourceIdAccessor: 'resourceId',
        resourceTitleAccessor: 'resourceTitle',
        resources: resources,
        scrollToTime: DateTime.local().toJSDate(),
        selectable: true,
        step: SchedulerConstants.step,
        showMultiDayTimes: true,
        timeslots: SchedulerConstants.timeslots,
        tooltipAccessor: '' as any,
        eventPropGetter: eventStyleGetter,
        getNow: () => DateTime.local().toJSDate(),
        onNavigate: onCurrentDateChange,
        onSelectEvent: onSelectEvent,
        onSelectSlot: onSelectSlot,
        onDragStart: onDragStart,
        onEventDrop: onEventDrop,
        onEventResize: onEventResize,
        resizable: canEditAppointment,
        resizableAccessor: handleResizableAccessor,
        draggableAccessor: handleDraggableAccessor,
        formats: {
          timeGutterFormat: (date: Date) => {
            return DateTime.fromJSDate(date).toFormat('h:mm a ');
          },
        },
        components: {
          toolbar: renderToolbar,
          event: renderNewEvent,
          resourceHeader: customResourceHeader,
        },
      }) as any,
    [
      isMobile,
      canEditAppointment,
      hasPermission,
      currentDate,
      appointmentsWithoutAllDayEvents,
      patientVisits,
      uniqueSelectedEvents,
      calendarTimeRange.end,
      calendarTimeRange.start,
      resources,
      eventStyleGetter,
      onCurrentDateChange,
      onSelectEvent,
      onSelectSlot,
      onDragStart,
      onEventDrop,
      onEventResize,
      handleResizableAccessor,
      handleDraggableAccessor,
      renderToolbar,
      renderNewEvent,
      customResourceHeader,
      showBackground,
    ]
  );

  useEffect(() => {
    if (showFullCalendar) {
      setCalendarTimeRange({
        start: DateTime.fromJSDate(currentDate).startOf('day').toJSDate(),
        end: DateTime.fromJSDate(currentDate).endOf('day').toJSDate(),
      });
    } else {
      setCalendarTimeRange({
        start: DateTime.fromJSDate(currentDate)
          .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
          .toJSDate(),
        end: DateTime.fromJSDate(currentDate)
          .set({ hour: 19, minute: 0, second: 0, millisecond: 0 })
          .toJSDate(),
      });
    }
  }, [showFullCalendar, currentDate]);

  return (
    <motion.div
      animate={selectedEvents.length > 0 ? 'open' : 'closed'}
      transition={{ duration: 0.5 }}
      variants={{
        open: { translateY: 50 },
        closed: { translateY: 0 },
      }}
      className='w-full max-w-full relative'
    >
      <CalendarEventsControl
        events={uniqueSelectedEvents}
        onClear={handleOnClearSelectedEvents}
        onEdit={onEditByControlPanel}
        onSelectAllSameColumn={onSelectAllSameColumnByControlPanel}
        onDuplicate={onDuplicateByControlPanel}
        onDelete={onDelete}
      />
      <div className='w-full overflow-auto'>
        <div className='w-full relative bg-white md:p-5 rounded-lg p-2'>
          <CustomWithDragAndDrop {...calendarProps} />
        </div>
      </div>
    </motion.div>
  );
};
