import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { LocalStorageHelper, LocalStorageKeys } from '@/helpers';

export enum Permission {
  CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
  'VIEW_TYPES:TELE' = 'VIEW_TYPES:TELE',
  'VIEW_ROOMS:TELE' = 'VIEW_ROOMS:TELE',
  'CREATE_APPOINTMENT:TEMPLATE' = 'CREATE_APPOINTMENT:TEMPLATE',
  DUPLICATE_APPOINTMENT = 'DUPLICATE_APPOINTMENT',
  DELETE_APPOINTMENT = 'DELETE_APPOINTMENT',
  EDIT_APPOINTMENT = 'EDIT_APPOINTMENT',
  VIEW_DAILY_NOTES = 'VIEW_DAILY_NOTES',
  EDIT_DAILY_NOTES = 'EDIT_DAILY_NOTES',
  VIEW_FILTERS = 'VIEW_FILTERS',
  EDIT_FILTERS = 'EDIT_FILTERS',
  CHANGE_LOCATION = 'CHANGE_LOCATION',
  VIEW_GOOGLE_APPOINTMENTS = 'VIEW_GOOGLE_APPOINTMENTS',
  VIEW_ATHENA_APPOINTMENTS = 'VIEW_ATHENA_APPOINTMENTS',
}

export type User = {
  permissions: Permission[];
};

export type AuthContextType = {
  user?: User;
  setUser: Dispatch<SetStateAction<User>>;
  hasPermission: (permission: Permission) => boolean;
};

const AuthContext = createContext<AuthContextType>({
  setUser: () => {},
  hasPermission: () => false,
});

export const useAuthContext = () => useContext(AuthContext);

export function AuthProvider({ children }: { children: ReactNode }) {
  const authStored = LocalStorageHelper.getItem(LocalStorageKeys.Auth) as User;
  const [user, setUser] = useState<User>(authStored);

  const hasPermission = useCallback(
    (permission: Permission) => {
      return !!user?.permissions?.includes(permission);
    },
    [user]
  );

  useEffect(() => {
    if (user) {
      LocalStorageHelper.setItem({
        key: LocalStorageKeys.Auth,
        value: user,
      });
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        setUser,
        user,
        hasPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
