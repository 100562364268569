import { VisitTypeTemplates } from '@/@types';
import { visitTypeTemplatesService } from '@/services';
import { useCallback, useState } from 'react';

export function useVisitTypeTemplates() {
  const [visitTypeTemplates, setVisitTypeTemplates] = useState<VisitTypeTemplates[]>([]);

  const findAllVisitTypeTemplates = useCallback(async () => {
    const { data } = await visitTypeTemplatesService.findAll();
    setVisitTypeTemplates(data);
  }, []);

  return {
    visitTypeTemplates,
    findAllVisitTypeTemplates,
  };
}
