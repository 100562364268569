import { Appointment, Atria } from '@/@types';
import { safeJSONParse } from '@/utils';
import { env } from '@/utils/constants';
import { Dispatch, SetStateAction, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';

enum SCHEDULER_MESSAGES {
  CREATE_APPOINTMENT = 'CREATE_APPOINTMENT',
  CREATE_MULTIPLE_APPOINTMENTs = 'CREATE_MULTIPLE_APPOINTMENTs',
  DELETE_APPOINTMENT = 'DELETE_APPOINTMENT',
  DELETE_MULTIPLE_APPOINTMENTS = 'DELETE_MULTIPLE_APPOINTMENTS',
  UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT',
  UPDATE_MULTIPLE_APPOINTMENTS = 'UPDATE_MULTIPLE_APPOINTMENTS',
  RESTORE_APPOINTMENTS = 'RESTORE_APPOINTMENTS',
  APPOINTMENTS_WITH_CONFLICT = 'APPOINTMENTS_WITH_CONFLICT',
}

export type CreateAppointment = Atria.Appointment & {
  externalClient: boolean;
};

export type UpdateAppointment = Atria.Appointment;

export type DeleteMultipleAppointments = {
  appointmentIds: number[];
};

export type SchedulerMessage = {
  client: string | undefined;
  type: string;
  payload:
    | Appointment
    | Pick<Appointment, 'appointmentId'>
    | UpdateAppointment
    | DeleteMultipleAppointments
    | UpdateAppointment[]
    | number[];
};

function isSchedulerEvent(message: any) {
  const msg = safeJSONParse(message.data);
  return Object.values(SCHEDULER_MESSAGES).includes(msg.type);
}

export const useSchedulerSocket = (
  date: Date,
  setAppointments: Dispatch<SetStateAction<Atria.Appointment[]>>
) => {
  const { lastJsonMessage } = useWebSocket<SchedulerMessage>(env.APP_SOCKET_URL, {
    share: true,
    filter: isSchedulerEvent,
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: (times) => times * 10000,
  });

  useEffect(() => {
    switch (lastJsonMessage?.type) {
      case SCHEDULER_MESSAGES.CREATE_APPOINTMENT: {
        const appointment = lastJsonMessage?.payload as CreateAppointment;
        if (
          !!date &&
          new Date(appointment.date).toISOString().slice(0, 10) === date.toISOString().slice(0, 10)
        ) {
          const newAppointment = {
            ...appointment,
            externalClient: true,
          };

          setAppointments((prev) => {
            if (prev.findIndex((x) => x.appointmentId === newAppointment.appointmentId) === -1) {
              return [...prev, newAppointment];
            }
            return prev;
          });
        }
        break;
      }
      case SCHEDULER_MESSAGES.CREATE_MULTIPLE_APPOINTMENTs: {
        const appointments = lastJsonMessage?.payload as CreateAppointment[];
        for (const appointment of appointments) {
          if (
            !!date &&
            new Date(appointment.date).toISOString().slice(0, 10) ===
              date.toISOString().slice(0, 10)
          ) {
            const newAppointment = {
              ...appointment,
              externalClient: true,
            };

            setAppointments((prev) => {
              if (prev.findIndex((x) => x.appointmentId === newAppointment.appointmentId) === -1) {
                return [...prev, newAppointment];
              }
              return prev;
            });
          }
        }

        break;
      }
      case SCHEDULER_MESSAGES.UPDATE_APPOINTMENT: {
        const updated = lastJsonMessage?.payload as UpdateAppointment;
        setAppointments((prev) => {
          const listWithoutUpdatedEvent = prev.filter(
            (app) => app.appointmentId !== updated.appointmentId
          );
          return [...listWithoutUpdatedEvent, updated];
        });
        break;
      }
      case SCHEDULER_MESSAGES.UPDATE_MULTIPLE_APPOINTMENTS: {
        const updatedAppointments = lastJsonMessage?.payload as UpdateAppointment[];
        setAppointments((prev) => {
          const listWithoutUpdatedEvent = prev.filter(
            (app) => !updatedAppointments.map((x) => x.appointmentId).includes(app.appointmentId)
          );
          return [...listWithoutUpdatedEvent, ...updatedAppointments];
        });
        break;
      }
      case SCHEDULER_MESSAGES.DELETE_APPOINTMENT: {
        const deletedAppointment = lastJsonMessage?.payload as { appointmentId: number };
        setAppointments((prev: Atria.Appointment[]) =>
          prev.filter((a) => a.appointmentId !== deletedAppointment?.appointmentId)
        );
        break;
      }
      case SCHEDULER_MESSAGES.DELETE_MULTIPLE_APPOINTMENTS: {
        const deletedAppointments = lastJsonMessage?.payload as DeleteMultipleAppointments;
        setAppointments((prev: Atria.Appointment[]) =>
          prev.filter((a) => !deletedAppointments?.appointmentIds?.includes(a.appointmentId))
        );

        break;
      }
      case SCHEDULER_MESSAGES.RESTORE_APPOINTMENTS: {
        const restoredAppointments = lastJsonMessage?.payload as Atria.Appointment[];
        setAppointments((prev) => [...prev, ...restoredAppointments]);
        break;
      }
      case SCHEDULER_MESSAGES.APPOINTMENTS_WITH_CONFLICT: {
        const conflictIds = lastJsonMessage?.payload as number[];
        setAppointments((prev) =>
          prev.map((appt) => ({
            ...appt,
            hasConflict: conflictIds.includes(appt.id),
          }))
        );
        break;
      }
      default:
        break;
    }
  }, [date, lastJsonMessage, setAppointments]);
};
