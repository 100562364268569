import { FindAllLocationsResponse } from '@/@types';
import { api } from '@/api';

async function findAll() {
  return api.get<FindAllLocationsResponse>(`/api/v1/location`);
}

export const locationService = {
  findAll,
};
