import { useCallback, useMemo } from 'react';

import {
  AppointmentWithDate,
  Atria,
  Origin,
  PatientVisit,
  Provider,
  Room,
  SelectOption,
  isPatientVisit,
} from '@/@types';
import { ChangeEvent } from '@/components/Calendar/Calendar';
import { useScheduler } from './useScheduler';
import { SchedulerHelper } from '@/helpers';

type Props = {
  roomsOptionsList: Room[];
  providersOptionsList: Provider[];
  appointmentsSelected: AppointmentWithDate[];
  patientVisitAppointments: AppointmentWithDate[];
};

export function useSchedulerDragDrop({
  appointmentsSelected,
  roomsOptionsList,
  providersOptionsList,
  patientVisitAppointments,
}: Props) {
  const { updateMultipleAppointments } = useScheduler();

  const roomsByAppointmentsSelectedId = useMemo(
    () =>
      appointmentsSelected.reduce((all, current) => {
        if (current.resourceId?.startsWith('provider')) {
          return all;
        }
        all[current.id] = all[current.id] || [];
        const roomLabel = Number(current.resourceId?.replace('room-', ''));
        if (!all[current.id].includes(roomLabel)) {
          all[current.id].push(roomLabel);
        }
        return all;
      }, {} as AppointmentRoomLabels),
    [appointmentsSelected]
  );

  const allRoomsByAppointmentId = useMemo(
    () =>
      appointmentsSelected.reduce((all, current) => {
        if (current.resourceId?.startsWith('provider') || all[current.id]) {
          return all;
        }
        all[current.id] = (current.rooms || []).map((r) => r.id);
        return all;
      }, {} as AppointmentRoomLabels),
    [appointmentsSelected]
  );

  const unselectedRoomsByAppointmentId = useMemo(() => {
    const result: AppointmentRoomIds = {};
    for (const [key, rooms] of Object.entries(allRoomsByAppointmentId)) {
      const appointmentId = Number(key);
      const roomsLabels = rooms.filter(
        (roomLabel) => !roomsByAppointmentsSelectedId[appointmentId].includes(roomLabel)
      );
      const roomsIds: number[] = [];
      for (const roomId of roomsLabels) {
        const item = roomsOptionsList.find((r) => r.id === roomId);
        if (!item) continue;
        roomsIds.push(item.id);
      }
      result[appointmentId] = roomsIds;
    }
    return result;
  }, [allRoomsByAppointmentId, roomsByAppointmentsSelectedId, roomsOptionsList]);

  const getRoomsIds = useCallback(
    (appointmentId: number, roomId?: number) => {
      if (roomId) {
        return [...unselectedRoomsByAppointmentId[appointmentId], roomId];
      }
      return unselectedRoomsByAppointmentId[appointmentId];
    },
    [unselectedRoomsByAppointmentId]
  );

  const getUniqueAppointmentsList = useCallback(
    (roomId?: number) => {
      const uniquesIds = [...new Set(appointmentsSelected.map((appt) => appt.id))];
      const appointments = uniquesIds.map((appointmentId) => {
        const apptSelected = appointmentsSelected.find(({ id }) => id === appointmentId)!;
        return {
          id: appointmentId,
          atriaAppointment: apptSelected.atriaAppointment,
          roomsIds: getRoomsIds(appointmentId, roomId),
        };
      });
      return appointments;
    },
    [appointmentsSelected, getRoomsIds]
  );

  const getUpdateParams = useCallback(
    (resourceId: string, appointment: AppointmentWithDate) => {
      let providerId = appointment.providerId;
      let providerName = appointment.providerName;
      let roomId: number | undefined;
      const isRoom = resourceId.startsWith('room');
      if (isRoom) {
        roomId = isRoom ? Number(resourceId.replace('room-', '')) : undefined;
      }
      const isProvider = resourceId.startsWith('provider');
      if (isProvider) {
        providerId = Number(resourceId.replace('provider-', ''));
        providerName = providersOptionsList.find(({ id }) => id === providerId)!.name;
      }
      return { providerId, providerName, roomId };
    },
    [providersOptionsList]
  );

  const updateMultipleAppointmentsOnDrop = useCallback(
    async (eventRaw: ChangeEvent) => {
      const { resourceId, start, event: appointment, events } = eventRaw;
      if (!isPatientVisit(appointment)) {
        const { roomId } = getUpdateParams(resourceId, appointment);
        const appointments = getUniqueAppointmentsList(roomId);
        const atriaAppts = appointments.filter((appt) => appt.atriaAppointment);
        const athenaAppts = appointments.filter((appt) => !appt.atriaAppointment);
        const roomsOptions = roomsOptionsList.map((er) => ({ id: er.id, label: er.name }));
        const data: Atria.UpdateMultipleAppointments.Body = {
          atria: atriaAppts.map((appt) => {
            const eventWithCorrectDate = events?.find((event) => event.id == appt.id);
            const selectedRooms = appt.roomsIds.map((roomIdsItem: number) => ({
              id: roomIdsItem,
            })) as SelectOption[];
            return {
              id: appt.id,
              roomsIds: SchedulerHelper.getRelatedNewRooms(selectedRooms, roomsOptions).map(
                (r) => r.id
              ),
              start: eventWithCorrectDate?.date.toISOString() || start.toISOString(),
              date: eventWithCorrectDate?.date.toISOString() || start.toISOString(),
              end: eventWithCorrectDate?.end.toISOString() || start.toISOString(),
            };
          }),
          athena: athenaAppts.map((appt) => {
            const selectedRooms = appt.roomsIds.map((roomIdsItem: number) => ({
              id: roomIdsItem,
            })) as SelectOption[];
            return {
              id: appt.id,
              roomsIds: SchedulerHelper.getRelatedNewRooms(selectedRooms, roomsOptions).map(
                (r) => r.id
              ),
            };
          }),
        };
        await updateMultipleAppointments(data);
        return true;
      }
    },
    [getUpdateParams, getUniqueAppointmentsList, updateMultipleAppointments, roomsOptionsList]
  );

  const updatePatientVisitAppointmentsOnDrop = useCallback(
    async ({ resourceId, event: appointment }: ChangeEvent) => {
      if (isPatientVisit(appointment)) {
        const atriaAppts = patientVisitAppointments.filter((appt) => appt.atriaAppointment);
        const athenaAppts = patientVisitAppointments.filter((appt) => !appt.atriaAppointment);

        const data: Atria.UpdateMultipleAppointments.Body = {
          atria: atriaAppts.map((appt) => ({
            id: appt.id,
            roomsIds: [Number(resourceId.replace('room-', ''))],
            date: appt.date.toISOString(),
            ...(appt.providerId &&
              appt.providerName && {
                providerId: appt.providerId,
                providerName: appt.providerName,
              }),
          })),
          athena: athenaAppts.map((appt) => ({
            id: appt.id,
            roomsIds: [Number(resourceId.replace('room-', ''))],
          })),
        };

        await updateMultipleAppointments(data);
        return true;
      }
    },
    [patientVisitAppointments, updateMultipleAppointments]
  );

  const handleDraggableAccessor = (event: AppointmentWithDate | PatientVisit) => {
    if (isPatientVisit(event)) {
      return true;
    } else {
      if (![Origin.Athena, Origin.Atria].includes(event.origin)) {
        return false;
      }
      const isAthena = !event.atriaAppointment;
      const isNotRoomEvent = !(event.resourceId || '').startsWith('room');
      if (isAthena && isNotRoomEvent) {
        return false;
      }
      return true;
    }
  };

  return {
    updateMultipleAppointmentsOnDrop,
    handleDraggableAccessor,
    updatePatientVisitAppointmentsOnDrop,
  };
}

type AppointmentRoomLabels = {
  [appointmentId: number]: number[];
};

type AppointmentRoomIds = {
  [appointmentId: number]: number[];
};
