import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { Cmo, Personnel, Provider, ProviderEquipment } from '@/@types';
import { CMOS } from '@/data/data';
import { providersService } from '@/services';

const isEquipment = 'Non-person entity';

type Type = {
  providers: Provider[];
  equipmentList: ProviderEquipment[];
  providersList: Personnel[];
  cmosFilterList: Cmo[];
  findAllProviders: () => Promise<void>;
};

const Context = createContext<Type>({
  providers: [],
  equipmentList: [],
  providersList: [],
  cmosFilterList: [],
  findAllProviders: async () => {},
});

type Props = {
  children?: React.ReactNode;
};

export function ProvidersContext({ children }: Props) {
  const [providers, setProviders] = useState<Provider[]>([]);

  const cmosFilterList: Cmo[] = useMemo(() => {
    return CMOS;
  }, []);

  const equipmentList: ProviderEquipment[] = useMemo(
    () =>
      providers
        .filter((p) => p.type === isEquipment)
        .map((i) => {
          return {
            resourceId: `equipment-${i.id}`,
            resourceTitle: i.name,
          };
        }) || [],
    [providers]
  );

  const providersList: Personnel[] = useMemo(
    () =>
      providers
        .filter((p) => p.type !== isEquipment)
        .map((equipment) => {
          return {
            ...equipment,
            resourceId: `provider-${equipment.id}`,
            resourceTitle: equipment.name,
            email: equipment.email,
            specialty: equipment.specialty,
          };
        }) || [],
    [providers]
  );

  const findAllProviders = useCallback(async () => {
    const { data } = await providersService.findAll();

    setProviders(
      data.map((item) => ({
        ...item,
        id: item.id,
        name:
          item.entityType === isEquipment
            ? item.displayName!
            : `${item.firstName || ''} ${item.lastName || ''}`.trim(),
        email: item.email,
        type: item.entityType,
        specialty: item.specialty,
      }))
    );
  }, []);

  return (
    <Context.Provider
      value={{
        providers,
        equipmentList,
        providersList,
        cmosFilterList,
        findAllProviders,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useProviders = () => useContext(Context);
