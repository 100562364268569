import { Resources } from '@/@types';

const filterResourceList = <T extends Resources>(list: T[], selectedList?: number[]): T[] => {
  const listHash = list.reduce((acc: Map<number, T>, resource) => {
    acc.set(Number(resource.resourceId.split('-')[1]), resource);
    return acc;
  }, new Map());

  return selectedList?.map((id) => listHash.get(id)).filter((b): b is T => !!b) ?? [];
};

export const ResourceHelper = {
  filterResourceList,
};
