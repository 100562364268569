// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import WeekWrapper from 'react-big-calendar/lib/addons/dragAndDrop/WeekWrapper';

export interface WeekWrapperProps {
  isAllDay?: boolean;
  slotMetrics: any; // Define the proper type for slotMetrics
  accessors: any; // Define the proper type for accessors
  getters: any; // Define the proper type for getters
  components: any; // Define the proper type for components
  resourceId: any;
  rtl?: boolean;
  localizer: any;
}

export default WeekWrapper as React.ComponentType<WeekWrapperProps>;
