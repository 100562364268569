import { useEffect, useState } from 'react';

export const useLocalStorage = <T,>(
  key: string,
  defaultValue: T,
  beforeSaveInLocalStorage: (T: T) => T = (state) => state,
  onStateStart: (T: T) => T = (state) => state
) => {
  const keyToUse = '@backoffice/' + key;
  const [value, setValue] = useState<T>(() => {
    const storedValue = localStorage.getItem(keyToUse);
    const stateTouse = storedValue ? JSON.parse(storedValue) : defaultValue;
    return onStateStart ? onStateStart(stateTouse) : stateTouse;
  });
  useEffect(() => {
    const filteredValue = beforeSaveInLocalStorage(value);
    localStorage.setItem(keyToUse, JSON.stringify(filteredValue));
  }, [keyToUse, value, beforeSaveInLocalStorage]);

  return [value, setValue] as const;
};
