type SelectActionStepProps = {
  handleCreateNew: VoidFunction;
  handleCreateFromTemplate: VoidFunction;
};

export const SelectActionStep = ({
  handleCreateNew,
  handleCreateFromTemplate,
}: SelectActionStepProps) => {
  return (
    <ul>
      <li
        className='p-[var(--space-regular)] hover:bg-[var(--gray-1)] cursor-pointer'
        onClick={handleCreateNew}
      >
        Create new
      </li>
      <li
        className='p-[var(--space-regular)] hover:bg-[var(--gray-1)] cursor-pointer'
        onClick={handleCreateFromTemplate}
      >
        Create from template
      </li>
    </ul>
  );
};
