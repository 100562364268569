import { Atria } from '@/@types';
import { api } from '@/api';

function searchPatientByNameOrPatientId(params: Atria.FindAllPatients.Params) {
  return api.get<Atria.FindAllPatients.Response>('/api/v1/patients', {
    params,
  });
}

export const patientsService = {
  searchPatientByNameOrPatientId,
};
