import { Checkbox } from 'primereact/checkbox';
import { useCallback, useMemo } from 'react';

type SelectFilterGroupHeaderProps = {
  label: string;
  options: any;
  optionGroupIdentify: string;
  selectedState: any;
  onChange: (e: any) => void;
};
export const SelectFilterGroupHeader = ({
  label,
  options,
  optionGroupIdentify,
  selectedState,
  onChange,
}: SelectFilterGroupHeaderProps) => {
  const selectedSet = useMemo(
    () => new Set(selectedState.map((selected: any) => selected[optionGroupIdentify])),
    [selectedState, optionGroupIdentify]
  );
  const optionsSet = useMemo(
    () => new Set(options.map((option: any) => option[optionGroupIdentify])),
    [options, optionGroupIdentify]
  );

  const isChecked = useMemo(() => {
    return options.every((option: any) => selectedSet.has(option[optionGroupIdentify]));
  }, [selectedSet, optionGroupIdentify, options]);

  const toogle = useCallback(
    (status: boolean) => {
      if (!status) {
        const newState = [
          ...(selectedState.filter(
            (selected: any) => !optionsSet.has(selected[optionGroupIdentify])
          ) || []),
        ];
        return onChange(newState);
      }
      const newState = [
        ...selectedState,
        ...(options.filter((option: any) => !selectedSet.has(option[optionGroupIdentify])) || []),
      ];
      return onChange(newState);
    },
    [optionsSet, selectedSet, optionGroupIdentify, selectedState, options, onChange]
  );
  const randomKey = useMemo(() => {
    return (Math.random() * 1000000000).toFixed();
  }, []);
  return (
    <>
      <Checkbox
        inputId={randomKey}
        name={optionGroupIdentify}
        onChange={(event) => {
          event.stopPropagation();
          toogle(event.checked!);
        }}
        checked={isChecked}
      />
      <label htmlFor={randomKey} className='ml-2 cursor-pointer text-xs'>
        {label}
      </label>
    </>
  );
};
