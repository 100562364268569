import { createContext, useContext } from 'react';

import { AppointmentType } from '@/@types';
import { useAppointmentTypes } from '@/hooks';

type Type = {
  appointmentTypesOptionsList: AppointmentType[];
  getAppointmentTypes: () => void;
};

type Props = {
  children?: React.ReactNode;
};

const Context = createContext<Type>({
  appointmentTypesOptionsList: [],
  getAppointmentTypes: () => {},
});

export function AppointmentTypeContext({ children }: Props) {
  const { appointmentTypesOptionsList, getAppointmentTypes } = useAppointmentTypes();

  return (
    <Context.Provider value={{ appointmentTypesOptionsList, getAppointmentTypes }}>
      {children}
    </Context.Provider>
  );
}

export const useAppointmentTypeContext = () => useContext(Context);
