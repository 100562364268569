import { env } from '@/utils/constants';
import * as Sentry from '@sentry/react';
import React from 'react';
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  environment: env.APP_NODE_ENV,
  dsn: env.APP_SENTRY_DSN,
  ignoreErrors: [/Network Error/],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/atria\.staging\.atria\.org\/api/,
    /^https:\/\/atria-api\.atria\.org\/api/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
