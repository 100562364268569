import { User } from '@/contexts';
import { ActionHistory as AC } from '@/hooks';

const prefix = '@scheduler/';

export type LocalStorageData = {
  expiresIn?: number;
} & (GoogleRefreshToken | Auth | ActionHistoryList | OktaTokenStorage);

export enum LocalStorageKeys {
  GoogleRefreshToken = `${prefix}googleRefreshToken`,
  Auth = `${prefix}Auth`,
  ActionHistory = `${prefix}actionHistory`,
  OktaTokenStorage = 'okta-token-storage',
}

type GoogleRefreshToken = {
  key: LocalStorageKeys.GoogleRefreshToken;
  value: string;
};

type Auth = {
  key: LocalStorageKeys.Auth;
  value: User;
};

type ActionHistoryList = {
  key: LocalStorageKeys.ActionHistory;
  value: AC[];
};

type OktaTokenStorage = {
  key: LocalStorageKeys.OktaTokenStorage;
  value: {
    idToken?: {
      claims?: {
        email?: string;
      };
    };
  };
};
