import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

export const DuplicateMultipleAppointmentsFormSchema = z.object({
  date: z.preprocess(
    (value: unknown) => {
      if (value) {
        return Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(new Date(String(value)));
      }
      return undefined;
    },
    z.string({ required_error: 'Please select a date' })
  ),
  rooms: z.array(z.object({ id: z.number(), label: z.string() }), {
    required_error: 'Please select a room',
  }),
});

export type DuplicateMultipleAppointmentsFormType = z.infer<
  typeof DuplicateMultipleAppointmentsFormSchema
>;

export const DuplicateMultipleAppointmentsFormSchemaResolver = zodResolver(
  DuplicateMultipleAppointmentsFormSchema
);
