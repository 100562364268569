// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
import { DnDContext } from 'react-big-calendar/lib/addons/dragAndDrop/DnDContext';

import { Context } from 'react';
import { AppointmentWithDate } from '@/@types';

export interface DragAndDropState {
  interacting: boolean;
  event?: AppointmentWithDate | null;
  direction?: string | null;
  action?: string | null;
}

export interface DnDContextValue {
  draggable: {
    onBeginAction(event: object, actionType: string, direction?: string): void;
    onDropFromOutside?(event: object): void;
    dragAndDropAction: DragAndDropState;
    onStart(): void;
    onEnd(event: object | null): void;
    dragFromOutsideItem?(): object;
    selectedEvents: AppointmentWithDate[];
    events: AppointmentWithDate[];
    min?: Date;
    max?: Date;
  };
}

export default DnDContext as Context<DnDContextValue>;
