import { CreateAccessTokenByOktaAccessTokenBody } from '@/@types';
import { api } from '@/api';

async function createAccessTokenByOktaAccessToken(accessToken: string) {
  return api.post<CreateAccessTokenByOktaAccessTokenBody>('/api/auth/okta/token', { accessToken });
}

async function deleteCookieAccess() {
  return api.delete('/api/auth/token');
}

export const authService = {
  createAccessTokenByOktaAccessToken,
  deleteCookieAccess,
};
