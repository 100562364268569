import { env } from '@/utils/constants';
import axios from 'axios';

export const api = axios.create({
  baseURL: env.APP_BACKEND_URL,
  params: { cacheIgnore: 'true' },
});

api.interceptors.request.use((config) => ({
  ...config,
  withCredentials: true,
}));

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      setTimeout(() => {
        Object.keys(localStorage).forEach((key) => {
          if (key.includes('okta')) {
            localStorage.removeItem(key);
          }
        });
        window.location.href = '/';
      }, 2000);
      return new Promise(() => {});
    }
    return Promise.reject(error);
  }
);

export default axios;
