import { createContext, useCallback, useContext, useState } from 'react';

type Props = {
  defaultId?: string;
  children: React.ReactNode;
};

const Context = createContext<AccordionContext>(undefined!);

export const useAccordionContext = () => useContext(Context);

export function Accordion({ defaultId, children }: Props) {
  const [selectedItem, setSelectedItem] = useState<string | undefined>(defaultId);

  const onToggle = useCallback((id: string) => {
    setSelectedItem((prev) => {
      if (prev === id) return;
      return id;
    });
  }, []);

  return <Context.Provider value={{ selectedItem, onToggle }}>{children}</Context.Provider>;
}

type AccordionContext = {
  selectedItem?: string;
  onToggle: (id: string) => void;
};
