import { createContext, useContext } from 'react';

import { FiltersProp } from '@/@types';
import { useFilters } from '@/hooks';

type Type = {
  filters: FiltersProp;
  setFilterItem: (key: keyof FiltersProp, value: any) => void;
  setFilters: React.Dispatch<React.SetStateAction<FiltersProp>>;
};

type Props = {
  children?: React.ReactNode;
};

const Context = createContext<Type>(undefined!);

export function FilterContext({ children }: Props) {
  const { filters, setFilterItem, setFilters } = useFilters();

  return (
    <Context.Provider value={{ filters, setFilterItem, setFilters }}>{children}</Context.Provider>
  );
}

export const useFiltersContext = () => useContext(Context);
