import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { loader } from 'react-global-loader';

import { AppointmentWithDate, Atria, SelectOption, AppointmentType } from '@/@types';
import { AppointmentWithTimeFormType } from '@/components/AppointmentForm/AppointmentFormSchema';
import { useToastContext } from '@/contexts';
import { appointmentsService } from '@/services';

export function useSchedulerDuplicateAppointment() {
  const { toast } = useToastContext();

  const getDuplicatedAppointmentData = useCallback(
    (appointment: AppointmentWithDate): AppointmentWithTimeFormType => {
      const { providers = [] } = appointment;
      const start = DateTime.fromJSDate(appointment.date).toFormat('T');
      const end = DateTime.fromJSDate(appointment.end).toFormat('T');
      const liaisonProviderSelected = providers.find((p) => p.type === 'LIAISON');
      const resourcesSelected = providers
        .filter((p) => p.type === 'EQUIPMENT')
        .map((p) => ({
          id: p.id,
          label: p.name,
        }));
      const additionalProvidersSelected = providers
        .filter((p) => p.type === 'ADDITIONAL')
        .map((p) => ({
          id: p.id,
          label: p.name,
        }));
      return {
        parentId: appointment.appointmentId,
        title: appointment.title,
        description: appointment.description,
        date: appointment.date.toISOString(),
        start,
        end,
        atriaAppointment: appointment.atriaAppointment,
        roomsSelected: appointment?.rooms || [],
        appointmentConfirmed: appointment.confirmed,
        ...(appointment?.typeId && {
          appointmentType: { id: appointment.typeId, name: appointment.type! },
        }),
        resourcesSelected,
        additionalProvidersSelected,
        ...(liaisonProviderSelected && {
          liaisonProviderSelected: {
            id: liaisonProviderSelected.id,
            label: liaisonProviderSelected.name,
          },
        }),
        ...(appointment.providerId && {
          primaryProviderSelected: {
            id: appointment.providerId!,
            label: appointment.providerName!,
          },
        }),
        ...(appointment.patientId && {
          patient: {
            patientName: appointment.patientName!,
            id: appointment.patientId!,
            firstName: appointment.firstName!,
            lastName: appointment.lastName!,
            firstNameUsed: appointment.firstNameUsed,
            primaryProviderId: appointment.patientPrimaryProviderId!,
          },
        }),
      };
    },
    []
  );

  const getDuplicateMultipleAppointments = useCallback(
    (
      appointments: AppointmentWithDate[],
      date: Date,
      appointmentTypes: AppointmentType[],
      roomsOptions: SelectOption[],
      rooms: SelectOption[]
    ) => {
      const list: Atria.CreateMultipleAppointments.Body['appointments'] = appointments.map(
        (appointment) => {
          let appointmentRooms: SelectOption[] = [...rooms];
          if (appointment.typeId) {
            const appointmentType = appointmentTypes.find((type) => type.id === appointment.typeId);
            if (
              appointmentType &&
              appointmentType.defaultRooms &&
              appointmentType.defaultRooms.length > 0
            ) {
              const locationDefaultRoom = roomsOptions.find((room) =>
                appointmentType.defaultRooms?.map((r) => r.roomId).includes(room.id)
              );

              if (
                locationDefaultRoom &&
                !rooms.find((room) => room.id === locationDefaultRoom.id)
              ) {
                appointmentRooms = [...rooms, locationDefaultRoom];
              }
            }
          }

          const appointmentDate = DateTime.fromJSDate(appointment.date);
          const newDate = DateTime.fromJSDate(date)
            .set({
              hour: appointmentDate.get('hour'),
              minute: appointmentDate.get('minute'),
              second: appointmentDate.get('second'),
            })
            .toJSDate()
            .toISOString();
          const duration = DateTime.fromJSDate(appointment.end).diff(
            DateTime.fromJSDate(appointment.date),
            'minutes'
          ).minutes;

          return {
            title: appointment.title,
            description: appointment.description,
            date: newDate,
            duration,
            rooms: appointmentRooms,
            confirmed: appointment.confirmed,
            type: appointment.type,
            typeId: appointment.typeId,
            ...(appointment?.providerId && {
              providerId: appointment.providerId,
              providerName: appointment.providerName!,
            }),
            providers: appointment.providers,
            ...(appointment.patientId && {
              patient: {
                id: appointment.patientId!,
                firstName: appointment.firstName!,
                lastName: appointment.lastName!,
                firstNameUsed: appointment.firstNameUsed,
                primaryProviderId: appointment.patientPrimaryProviderId!,
              },
            }),
          };
        }
      );
      return { appointments: list };
    },
    []
  );

  const createMultipleAppointments = useCallback(
    async (list: Atria.CreateMultipleAppointments.Body) => {
      try {
        loader.show();
        const result = await appointmentsService.createMultipleAppointments(list);
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Appointments duplicated successfully',
          life: 3000,
        });
        return result.data;
      } catch (error) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to duplicate multiple appointments',
          life: 3000,
        });
      } finally {
        loader.hide();
      }
    },
    [toast]
  );

  return {
    getDuplicateMultipleAppointments,
    getDuplicatedAppointmentData,
    createMultipleAppointments,
  };
}
