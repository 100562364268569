import { useState, useCallback } from 'react';

import { AppointmentType } from '@/@types';
import { appointmentsService } from '@/services';

export function useAppointmentTypes() {
  const [appointmentTypesOptionsList, setAppointmentTypes] = useState<AppointmentType[]>([]);

  const getAppointmentTypes = useCallback(async () => {
    const { data: types } = await appointmentsService.findAppointmentTypes();
    setAppointmentTypes(types);
  }, []);

  return {
    appointmentTypesOptionsList,
    getAppointmentTypes,
  };
}
