import AtriaWord from '../../assets/atria-word-stone.svg';

export const NotAuthorizedHeader = () => {
  return (
    <>
      <div
        className='h-16 p-4 sticky top-0 z-10 shadow-md flex align-center justify-between'
        style={{ backgroundColor: 'var(--primary-fern)' }}
      >
        <img className='h-full' src={AtriaWord} alt='AtriaWord' />
      </div>
    </>
  );
};
