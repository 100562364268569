import { GoogleOAuthProvider } from '@react-oauth/google';
import { Settings } from 'luxon';
import { ProgressSpinner } from 'primereact/progressspinner';
import ReactDOM from 'react-dom/client';
import { LoaderContainer } from 'react-global-loader';
import { BrowserRouter } from 'react-router-dom';

import { AppContexts } from '@/contexts';
import './assets/fonts/Atlas Grotesk/atlasGrotesk.css';
import './assets/fonts/Ivar Display/ivarDisplay.css';
import './assets/fonts/Scto Grotesk/sctoGroteskA.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-green/theme.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { App } from './App';
import './index.css';
import './theme.css';
import { env } from '@/utils/constants';

Settings.defaultZone = 'America/New_York';

const renderApp = async () => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <GoogleOAuthProvider clientId={env.APP_GOOGLE_CLIENT_ID}>
      <BrowserRouter>
        <AppContexts>
          <App />
        </AppContexts>
      </BrowserRouter>
      <LoaderContainer>
        <ProgressSpinner className='h-28 w-28 duration-200' strokeWidth='3' fill='transparent' />
      </LoaderContainer>
    </GoogleOAuthProvider>
  );
};

renderApp();
